import axiosInstance from '../api/axios';
import useAuth from './useAuth';
import React, { useEffect, useState } from 'react';
import { USER_REFRESH } from "../apiUrls"

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();
    const [accessToken, setAccessToken] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + auth.accessToken;
                const response = await axiosInstance.post(USER_REFRESH, {
                    refresh: auth.refreshToken
                });
                setAccessToken(response.data.access);
                axiosInstance.defaults.headers['Authorization'] = null; // Reset the Authorization header

            } catch (error) {
                console.error('Error refreshing token:', error);
            }
        };

        fetchData(); // Call fetchData immediately when the component mounts
    }, [auth.accessToken, auth.refreshToken, setAuth]); // Include setAccessToken in the dependency array

    const refresh = () => {
        setAuth(prev => ({
            ...prev,
            accessToken: accessToken
        }));
        return accessToken;
    };

    return refresh;
};

export default useRefreshToken;
