// import axios from 'axios';
import React, { useState, useEffect, useRef } from "react";
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axios";
import assetImports from "../AssetImport/assetImports";
import { CREATE_PARENT_PROFILE_URL } from "../../apiUrls";
import styles from "./styles.css";
import getCurrentProfile from "../Profile/profileAPI";
import getCenterProfile from "../Profile/centerProfileAPI";
import useRefreshToken from "../../hooks/useRefreshToken";
import ApiKeyFetcher from "../ApiKeyFetcher";
import { jwtDecode } from "jwt-decode";
import MapComponent from "../MapComponent";

function CreateParentProfile() {
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const [formData, updateFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isCareCenter, setIsCareCenter] = useState(false);
  const [isParent, setIsParent] = useState(false);

  if (!auth.accessToken) {
    navigate("/");
  }

  useEffect(() => {
    if (auth.accessToken) {
      const decodedToken = jwtDecode(auth.accessToken);
      setIsCareCenter(decodedToken.is_care_center === true);
      setIsParent(decodedToken.is_parent === true);
    }
    const fetchProfile = async () => {
      try {
        if (isParent) {
          const response = await getCurrentProfile(auth.accessToken);
          if (response.data) {
            // User profile exists, redirect to dashboard
            navigate("/dashboard");
          }
        } else if (isCareCenter) {
          const response = await getCenterProfile(auth.accessToken);
          if (response.data) {
            // User profile exists, redirect to dashboard
            navigate("/center-dashboard");
          } else {
            // User profile does not exist, redirect to create profile
            navigate("/create-center-profile");
          }
        } else {
          const response = await getCurrentProfile(auth.accessToken);
          if (response.data) {
            // User profile exists, redirect to dashboard
            navigate("/dashboard");
          } else {
          }
        }
      } catch (error) {
        if (error.response.status === 400) {
          console.log("No Profile Exists");
        } else if (error.response && error.response.status === 401) {
          console.log("Access token expired or invalid, refreshing token...");
          await refresh();
        } else {
          //setErrMsg("Error fetching current profile. Please try again later."); // Handle other errors
        }
        // setErrMsg('Error fetching current profile:', error);
      }
    };
    fetchProfile();
  }, [auth.accessToken, navigate, isCareCenter, isParent]);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axiosInstance.post(CREATE_PARENT_PROFILE_URL, formData, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      navigate("/dashboard");
    } catch (error) {
      setErrMsg("Error creating center profile:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <div
        id="profileSignup"
        className="sign-up-div login-holder flex flex-wrap justify-center items-center relative"
      >
        {loading && (
          <div className="overlay-loading">
            <img src={assetImports.loadingGif} alt="Loading" />
          </div>
        )}
        <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
        <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
        <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
        <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
        <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
        <div className="container relative login-container">
          <img className="logo-image" src={assetImports.logo} alt="Logo" />
          <div className="relative login-box">
            <p
              id="loginerror"
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
            <div className="login-form border-primary relative">
              <h1 className="font-lguy primary text-center">
                CREATE A PROFILE
              </h1>
              <form
                id="parent-sign-up-form"
                className="sign-up-form nolabel"
                onSubmit={handleFormSubmit}
              >
                <input
                  className="border-primary half"
                  type="text"
                  name="first_name"
                  id="first_name"
                  defaultValue={formData.first_name}
                  required
                  placeholder="First Name"
                  onChange={handleChange}
                />
                <input
                  className="border-primary half"
                  type="text"
                  name="last_name"
                  id="last_name"
                  defaultValue={formData.last_name}
                  required
                  placeholder="Last Name"
                  onChange={handleChange}
                />
                <input
                  className="border-primary"
                  type="tel"
                  name="phone"
                  defaultValue={formData.phone}
                  required
                  placeholder="Phone Number"
                  onChange={handleChange}
                />
                <input
                  className="border-primary"
                  type="email"
                  name="email"
                  id="email"
                  defaultValue={auth.email}
                  required
                  placeholder="Email"
                  readOnly
                />
                <ApiKeyFetcher>
                  <MapComponent
                    formData={formData}
                    updateFormData={updateFormData}
                    city={formData.city}
                    postalCode={formData.postal_code}
                    province={formData.province}
                  />
                </ApiKeyFetcher>
                <button className="full primary button-custom" type="submit">
                  Submit
                </button>
              </form>
              <div className="link-logout-text">
                <Link to="/logout" className="pink">
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CreateParentProfile;
