import React, { useState } from "react";
import assetImports from "./AssetImport/assetImports";
import { Link } from "react-router-dom";
import Preloader from "../Preloader";

const NotFound = () => {
  const [dataLoaded, setDataLoaded] = useState(false);

  setTimeout(() => {
    setDataLoaded(true);
  }, 1000);

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section className="login-holder flex flex-wrap justify-center items-center relative">
      <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
      <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
      <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
      <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
      <div className="container relative login-container">
        <img className="logo-image" src={assetImports.logo} alt="Logo" />
        <div className="relative login-box">
          <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
          <div className="login-form relative border-primary">
            {/* <img src={success} alt="success_img" className={styles.success_img} /> */}
            <h1 className="font-lguy primary text-center logout-text">
              404 not found
            </h1>
            <div className="flex flex-wrap justify-center items-center">
              <Link className="button-custom pink" to="/">
                {" "}
                LOG IN{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
