import { useEffect, useState, Fragment } from "react";
import { useLocation, Link } from "react-router-dom";
import axiosInstance from "../../api/axios";
// import success from "../../images/success.png";
import styles from "./styles.css";
import assetImports from "../AssetImport/assetImports";
import { EMAIL_VERIFY } from "../../apiUrls";
import Preloader from "../../Preloader";

const EmailVerify = () => {
  const [validUrl, setValidUrl] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [dataLoaded, setDataLoaded] = useState(false);

  setTimeout(() => {
    setDataLoaded(true);
  }, 1000);

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        // console.log(token);
        const data = await axiosInstance.get(`${EMAIL_VERIFY}?token=${token}`);
        // console.log(data);
        setValidUrl(true);
      } catch (error) {
        // console.log(error);
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  }, [token]);

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <Fragment>
      {validUrl ? (
        <>
          <section className="login-holder flex flex-wrap justify-center items-center relative">
            <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
            <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
            <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
            <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
            <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
            <div className="container relative login-container">
              <img className="logo-image" src={assetImports.logo} alt="Logo" />
              <div className="relative login-box">
                <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
                <div className="login-form relative border-primary">
                  {/* <img src={success} alt="success_img" className={styles.success_img} /> */}
                  <h1 className="font-lguy primary text-center logout-text">
                    YOUR EMAIL IS VERIFIED
                  </h1>
                  <p className="primary text-center">
                    Please proceed to logging in
                  </p>
                  <br />
                  <div className="flex flex-wrap justify-center items-center">
                    <Link className="button-custom pink" to="/">
                      {" "}
                      LOG IN{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section className="login-holder flex flex-wrap justify-center items-center relative">
            <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
            <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
            <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
            <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
            <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
            <div className="container relative login-container">
              <img className="logo-image" src={assetImports.logo} alt="Logo" />
              <div className="relative login-box">
                <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
                <div className="login-form relative border-primary">
                  {/* <img src={success} alt="success_img" className={styles.success_img} /> */}
                  <h1 className="font-lguy primary text-center logout-text">
                    404 not found
                  </h1>
                  <div className="flex flex-wrap justify-center items-center">
                    <Link className="button-custom pink" to="/">
                      {" "}
                      LOG IN{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Fragment>
  );
};

export default EmailVerify;
