import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosInstance from "../../../api/axios";
import assetImports from "../../AssetImport/assetImports";
import ProfileBar from "../../NameProfileBar/nameprofilebar";
import useAuth from "../../../hooks/useAuth";
import getCurrentProfile from "../../Profile/profileAPI";
import useRefreshToken from "../../../hooks/useRefreshToken";
import Banner from "../../Banner/banner";
import Sidebar from "../../Sidebar/sidebar";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  GET_WAITLISTS_BY_CENTER_BY_SLUG,
  GET_CENTER_PROFILE_BY_SLUG,
  GET_MY_CHILDREN_LIST,
  REGISTER_CHILD_ON_WAITLIST,
} from "../../../apiUrls";
import Preloader from "../../../Preloader";

const DaycareDetails = ({ slug }) => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const errRef = useRef();

  const [minDate, setMinDate] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [formData, updateFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [profileFirstName, setProfileFirstName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [waitlists, setWaitlists] = useState([]);
  const [children, setChildren] = useState([]);
  const [centerProfile, setCenterProfile] = useState("");
  const [successAdd, setSuccessAdd] = useState(false);
  const [childName, setChildName] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const handleCloseSidebar = () => setIsSidebarOpen(false);

  useEffect(() => {
    const today = new Date();
    const formattedMinDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    setMinDate(formattedMinDate);
  }, []);

  const fetchProfile = useCallback(async () => {
    try {
      const response = await getCurrentProfile(auth.accessToken);
      setProfileFirstName(response.data.first_name);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Access token expired or invalid, refreshing token...");
        await refresh();
      } else {
        console.log("Error fetching current profile:", error);
        setErrMsg("Error fetching current profile. Please try again later.");
      }
    } finally {
      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
    }
  }, [auth.accessToken, refresh]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const fetchData = useCallback(
    async (url, setter) => {
      try {
        const response = await axiosInstance.get(url, {
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        });
        setter(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setErrMsg("Not Authorized");
        } else {
          console.log(error);
        }
      }
    },
    [auth.accessToken]
  );

  useEffect(() => {
    fetchData(`${GET_CENTER_PROFILE_BY_SLUG}?slug=${slug}`, setCenterProfile);
    fetchData(`${GET_WAITLISTS_BY_CENTER_BY_SLUG}?slug=${slug}`, setWaitlists);
    fetchData(GET_MY_CHILDREN_LIST, setChildren);
  }, [slug, fetchData]);

  const handleChange = (eventOrSelectedOption) => {
    const isSelectOption = !eventOrSelectedOption.target;
    if (isSelectOption) {
      setChildName(eventOrSelectedOption.label);
      updateFormData((prevFormData) => ({
        ...prevFormData,
        child: eventOrSelectedOption.value,
      }));
    } else {
      const { name, value, type, checked } = eventOrSelectedOption.target;
      const newValue = type === "checkbox" ? checked : value;
      updateFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newValue,
      }));
    }
  };

  const handleFormSubmit = (event, waitlistId) => {
    event.preventDefault();
    setLoading(true);
    updateFormData((prevFormData) => ({
      ...prevFormData,
      center: centerProfile.id,
      waitlist: waitlistId,
    }));
  };

  useEffect(() => {
    const submitFormData = async () => {
      try {
        await axiosInstance.post(REGISTER_CHILD_ON_WAITLIST, formData, {
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        });
        setSuccessAdd(true);
      } catch (error) {
        const waitlistId = formData.waitlist;
        const errorMsg =
          error.response?.status === 400
            ? error.response.data.errors[0]
            : "Error registering child to this waitlist";
        setErrMsg((prevState) => {
          const updatedErrMsg = { ...prevState };
          updatedErrMsg[waitlistId] = errorMsg.toString();
          return updatedErrMsg;
        });
        updateFormData({});
      } finally {
        setLoading(false);
      }
    };

    if (formData.center !== undefined && formData.waitlist) {
      submitFormData();
    }
  }, [formData, auth.accessToken]);

  useEffect(() => {
    setErrMsg({});
  }, [waitlists]);

  const handleUpdate = () => {
    window.location.reload(); // Reloads the page
  };

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section>
      {loading && (
        <div className="overlay-loading">
          <img src={assetImports.loadingGif} alt="Loading" />
        </div>
      )}
      <img id="blueBlurbProfile" src={assetImports.blueBlurb} alt="" />
      <img id="greenBlurbProfile" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurbFullProfile" src={assetImports.pinkBlurbFull} alt="" />
      <div>
        <Banner
          toggleSidebar={toggleSidebar}
          title={`${profileFirstName}'s CHILD INFO`}
        />
      </div>
      <div className="flex flex-wrap sidebar-container relative">
        <Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
        <div className="content-sidebar card-list">
          <div className="inner relative no-mobile-inner">
            <img
              id="dcsearch"
              className="hide-for-mobile"
              src={assetImports.pinkBlurbFull}
              alt=""
            />
            <div className="bigger-image">
              <ProfileBar
                name={`Welcome to ${centerProfile.center_name}`}
                text="Let's help you with your Day Care search"
                img={assetImports.profileAvatar}
              />
            </div>

            <div className="col-container relative bg-white">
              {successAdd && (
                <div className="overlay-loading dark">
                  <div className="modal-content flex flex-wrap items-center justify-center wider">
                    <div className="modal-inner bigger">
                      <img
                        className="success"
                        src={assetImports.successSvg}
                        alt="Successfull Update"
                      />
                      <h1 className="text-center logout-text">
                        {childName} has been successfully added to{" "}
                        {centerProfile.center_name} waitlist!
                      </h1>
                      <p>
                        We're excited to inform you that the capability to
                        monitor and view your precise position on the waitlist
                        has been seamlessly integrated into your dashboard,
                        providing you with immediate access and visibility to
                        your status whenever you choose to check.
                      </p>
                      <button className="close-overlay" onClick={handleUpdate}>
                        <span>X</span>
                      </button>
                      <button
                        className="button-custom primary lowercase"
                        onClick={handleUpdate}
                      >
                        Close
                      </button>
                      <Link
                        className="button-custom primary lowercase"
                        to="/dashboard"
                      >
                        Go To Dashboard
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              <h3 className="text-filter uppercase font-semibold">
                {centerProfile.city}, {centerProfile.province}
              </h3>
              {/* <p
                id="loginerror"
                ref={errRef}
                className={errMsg ? "errmsg pink bold" : "offscreen pink bold"}
                aria-live="assertive"
              >
                {errMsg}
              </p> */}
              <div className="border-secondary center-holder">
                <h3 className="text-filter">{centerProfile.center_name}</h3>
                <p className="smaller black">{`Address: ${centerProfile.street_address}, ${centerProfile.city}, ${centerProfile.province}`}</p>
                <p className="smaller">
                  <a
                    className="black"
                    href={`mailto:${centerProfile.email}`}
                    aria-label={`Email ${centerProfile.center_name}`}
                    target="_blank"
                  >
                    {`Email: ${centerProfile.email}`}
                  </a>
                </p>
                <p className="smaller final-para">
                  <a
                    className="black"
                    href={`tel:${centerProfile.phone}`}
                    aria-label={`Call ${centerProfile.center_name}`}
                    target="_blank"
                  >
                    {`Phone: ${centerProfile.phone}`}
                  </a>
                </p>

                {waitlists.length === 0 ? (
                  <p className="pink">No waitlists found.</p>
                ) : (
                  waitlists.map((col) =>
                    col.full ? (
                      <div
                        key={col.id}
                        className="border-secondary daycare-card waitlist-card"
                      >
                        <div className="card-body-flex">
                          <h3 className="text-white">
                            {`${col.list_type}`} Waitlist - Last number on
                            waitlist ({`${col.size}`})
                          </h3>
                          <p className="text-white bold">*Waitlist Full*</p>
                        </div>
                      </div>
                    ) : (
                      <form
                        key={col.id}
                        className="border-secondary daycare-card waitlist-card"
                        onSubmit={(e) => handleFormSubmit(e, col.id)}
                      >
                        <div className="card-body-flex">
                          <h3 className="text-white">
                            {`${col.list_type}`} Waitlist - Last number on
                            waitlist ({`${col.size}`})
                          </h3>
                          <div className="flex flex-wrap select-child-container">
                            <div className="block-field">
                              <label
                                htmlFor="child"
                                className="text-white nomargin smaller italic"
                              >
                                Please select the child you want to add to this
                                waitlist*
                              </label>
                              <Select
                                name="child"
                                className="form-select"
                                onChange={handleChange}
                                options={children.map((child) => ({
                                  value: child.id,
                                  label: `${child.first_name} ${child.last_name}`,
                                }))}
                                isMulti={false}
                                placeholder="Select Child"
                              />
                            </div>
                            <div className="block-field">
                              <label
                                htmlFor="expected_start_date"
                                className="text-white nomargin smaller italic"
                              >
                                Please pick an expected start date for your
                                child*
                              </label>
                              <input
                                placeholder="Expected Start Date"
                                type="date"
                                className="date"
                                onChange={handleChange}
                                name="expected_start_date"
                                required
                                min={minDate}
                              />
                            </div>
                            <button className="submit">Add Child</button>
                          </div>
                          {errMsg[col.id] && (
                            <div className="error-block">
                              <p
                                ref={errRef}
                                className={
                                  errMsg[col.id] ? "errmsg" : "offscreen"
                                }
                                aria-live="assertive"
                              >
                                {errMsg[col.id] && errMsg[col.id].toString()}{" "}
                                {/* Ensure the error message is rendered as a string */}
                              </p>
                            </div>
                          )}
                        </div>
                      </form>
                    )
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DaycareDetails;
