import React, { useState, useEffect } from 'react';
import axiosInstance from 'axios';


function Parents() {

    // let baseUrl = axiosInstance.defaults.baseURL;
    const [parents, setParents] = useState([]);
    useEffect(() => {
        const loadParents = async () => {
            const result = await axiosInstance.get(`http://localhost:8000/api/get_parents_list/`);
            setParents(result.data);
        };
        loadParents();
    }, [parents]);
    return (
        <>
        <div>
            {parents.map((parent) => (
                <div key={parent.id}>
                    <h2>{parent.first_name} {parent.last_name}</h2>
                    <p>Phone: {parent.phone}</p>
                    <p>Email: {parent.email}</p>
                    <hr />
                </div>
            
            ))}
        </div>
        </>
    );
}

export default Parents;