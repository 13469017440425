import React, { useEffect, useState } from "react";
import Select from "react-select";
import InputRange from "react-input-range";
import assetImports from "../../AssetImport/assetImports";

const FilterSection = ({
  provinces,
  cities,
  filterProvince,
  filterCity,
  maxDistance,
  searchTerm,
  handleFilterProvinceChange,
  handleFilterCityChange,
  handleSearchTermChange,
  handleMaxDistanceChange,
  resetFilter,
  showDisplay,
  toggleDistanceDisplay,
}) => {
  useEffect(() => {
    // Reset city when province selection is cleared
    if (!filterProvince) {
      handleFilterCityChange("");
    }
  }, [filterProvince, handleFilterCityChange]);
  return (
    <div className="flex flex-wrap relative filter-container">
      <div className="filter-box-holder flex flex-wrap">
        <div className="filter-box">
          <Select
            className="form-select"
            value={
              filterProvince
                ? { value: filterProvince, label: filterProvince }
                : null
            }
            onChange={(selectedOption) => {
              handleFilterProvinceChange(selectedOption.value);
            }}
            options={[
              { value: "", label: "ALL PROVINCES" },
              ...provinces.map((province) => ({
                value: province,
                label: province,
              })),
            ]}
            placeholder="Province"
          />
        </div>
        <div className="filter-box">
          <Select
            className="form-select"
            value={filterCity ? { value: filterCity, label: filterCity } : null}
            onChange={(selectedOption) => {
              handleFilterCityChange(selectedOption.value);
            }}
            options={[
              { value: "", label: "ALL" },
              ...cities.map((city) => ({ value: city, label: city })),
            ]}
            placeholder="City"
            isDisabled={!filterProvince}
            escapeClearsValue
          />
        </div>
        <div className="filter-box search">
          <input
            type="text"
            className="form-control"
            id="search"
            placeholder="Search Daycare"
            value={searchTerm}
            onChange={(e) => {
              handleSearchTermChange(e.target.value);
            }}
          />
        </div>

        <div className="full">
          <div className="range-box">
            <div className="flex flex-wrap justify-between items-end">
              <div className="flex flex-wrap justify-between col-distance">
                <img
                  className="geolocation"
                  src={assetImports.geolocationIcon}
                  alt=""
                />
                <div className="range-col">
                  {/* <p className='rangeSlider'>*Distance Slider*</p> */}
                  <InputRange
                    formatLabel={(value) => `${value}km`}
                    minValue={0}
                    maxValue={200}
                    value={maxDistance}
                    onChange={handleMaxDistanceChange}
                    disabled={!showDisplay}
                  />
                </div>
              </div>
              <button
                type="button"
                className="button-custom primary distance"
                onClick={toggleDistanceDisplay}
              >
                {showDisplay ? "Disable Slider" : "Enable Slider"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-box-reset flex flex-wrap items-start justify-between">
        <button
          type="button"
          className="button-custom primary"
          onClick={resetFilter}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default FilterSection;
