// profileAPI.js
import axiosInstance from '../../api/axios';
import { GET_CURRENT_CENTER_PROFILE_URL } from '../../apiUrls';

const getCenterProfile = async (accessToken) => {
    try {
        const response = await axiosInstance.get(GET_CURRENT_CENTER_PROFILE_URL, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching current profile:', error);
        throw error;
    }
};

export default getCenterProfile;
