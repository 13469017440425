import axiosInstance from "../api/axios";
import useAuth from "./useAuth";
import { USER_LOGOUT } from "../apiUrls"

const useLogout = () => {
    const { auth, setAuth } = useAuth();

    const logout = async () => {
        try {
            axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + auth.accessToken;
            const response = await axiosInstance.post(USER_LOGOUT, {
                refresh: auth.refreshToken
            });
            setAuth('');
            axiosInstance.defaults.headers['Authorization'] = null; // Reset the Authorization header
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout