import React, { useState, useEffect } from "react";
import assetImports from "../AssetImport/assetImports";
import { Link, useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import Preloader from "../../Preloader";

function Logout() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const logout = useLogout();
  const navigate = useNavigate();

  const signOut = async () => {
    await logout();

    setTimeout(() => {
      setDataLoaded(true);
    }, 500);
    navigate("/logout");
  };

  useEffect(() => {
    // Call the signOut function when the component mounts
    signOut();
  }, []);

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section className="login-holder flex flex-wrap justify-center items-center relative">
      <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
      <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
      <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
      <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
      <div className="container relative login-container">
        <img className="logo-image" src={assetImports.logo} alt="Logo" />
        <div className="relative login-box">
          <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
          <div className="login-form relative border-primary">
            <h1 className="font-lguy primary text-center logout-text">
              YOU HAVE BEEN SUCCESSFULLY LOGGED OUT
            </h1>
            <div className="flex flex-wrap justify-center items-center">
              <Link className="button-custom pink" to="/">
                {" "}
                LOG IN{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Logout;
