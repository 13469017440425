import React, { useEffect, useState } from "react";
import axiosInstance from "../../../api/axios.js";
import useAuth from "../../../hooks/useAuth";
import useRefreshToken from "../../../hooks/useRefreshToken";
import getCenterProfile from "../../Profile/centerProfileAPI";
import assetImports from "../../AssetImport/assetImports";
import { useNavigate, Link, useParams } from "react-router-dom";
import DaycareSidebar from "../../Sidebar/daycareSidebar.js";
import { Tooltip } from "react-tooltip";
import { REJECTED_REGISTRATIONS } from "../../../apiUrls.js";
import styles from "./styles.css";
import BannerDaycare from "../../Banner/bannerDaycare.js";
import Preloader from "../../../Preloader.js";

const RejectedListSingle = ({ slug }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const [center_name, setCenterName] = useState("");
  const [registrations, setRegistrations] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [errMsgUpdate, setErrMsgWaitlist] = useState("");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    // Check if the viewport width is less than a certain value (e.g., 768 pixels for mobile)
    const isMobile = window.innerWidth < 768;

    // Set isOpen to true if it's a mobile viewport
    setIsSidebarOpen(!isMobile);
  }, []); // Only run this effect once when the component mounts

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); // Function to close the sidebar
  };

  const openModal = (
    parentName,
    parentEmail,
    parentPhone,
    childName,
    rejectionReason
  ) => {
    setModalData({
      parentName,
      parentEmail,
      parentPhone,
      childName,
      rejectionReason,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // You can also clear modalData here if needed
  };

  useEffect(() => {
    const getWaitlists = async () => {
      try {
        const waitlists = await axiosInstance.get(REJECTED_REGISTRATIONS, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        });
        const filteredData = waitlists.data[slug] || [];
        setRegistrations(filteredData);
        // console.log(filteredData);
      } catch (error) {
        // console.error('Error fetching waitlists:', error);
        setErrMsgWaitlist("Error fetching waitlists. Please try again later.");
      }
      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
    };
    getWaitlists();
  }, [auth.accessToken]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getCenterProfile(auth.accessToken);
        const data = response.data;
        if (data && data.center_name) {
          setCenterName(data.center_name);
        } else {
          console.error("Invalid response format:", data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Access token expired or invalid, refreshing token...");
          await refresh();
        } else {
          navigate("/create-center-profile");
          setErrMsg(`Error fetching center profile: ${error.message}`); // Handle other errors
        }
      }
    };
    fetchProfile();
  }, [auth.accessToken]);

  function formatDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Function to get ordinal suffix for day
    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return `${day}${getOrdinalSuffix(day)} ${months[monthIndex]}, ${year}`;
  }

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section>
      {isModalOpen && (
        <div className="overlay-loading dark">
          <div className="modal-content flex flex-wrap items-center justify-center rounded-edge">
            <div className="modal-inner full">
              <div className="view-profile-list flex flex-wrap items-center">
                <div className="profile-content">
                  <p>
                    <span className="font-semibold">{modalData.childName}</span>
                  </p>
                  <p>
                    <span className="font-semibold">
                      Parent: {modalData.parentName}
                    </span>
                  </p>
                  <p>
                    <span className="font-semibold">Contact Details:</span>
                  </p>
                  <p className="lil-m">
                    <a
                      className="black"
                      target="_blank"
                      href={`tel:${modalData.parentPhone}`}
                    >
                      {modalData.parentPhone}
                    </a>
                  </p>
                  <p className="lil-m">
                    <a
                      className="black"
                      target="_blank"
                      href={`mailto:${modalData.parentEmail}`}
                    >
                      {modalData.parentEmail}
                    </a>
                  </p>
                  <p>
                    <span className="font-semibold">Rejection Reason:</span>
                  </p>
                  <p>{modalData.rejectionReason || "None"}</p>
                  <a
                    className="primary button-custom"
                    href={`mailto:${modalData.parentEmail}`}
                    target="_blank"
                  >
                    Send Message
                  </a>
                </div>
                <div>
                  <div className="border-pink bg-pink avatar-div items-center justify-center flex flex-wrap mx-auto">
                    <img src={assetImports.avatar} alt="" />
                  </div>
                </div>
              </div>
              <button className="close-overlay" onClick={closeModal}>
                <span>X</span>
              </button>
            </div>
          </div>
        </div>
      )}
      <img id="blueBlurbProfile" src={assetImports.blueBlurb} alt="" />
      <img id="greenBlurbProfile" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurbFullProfile" src={assetImports.pinkBlurbFull} alt="" />
      <div>
        <BannerDaycare
          toggleSidebar={toggleSidebar}
          title={`${center_name}'s DASHBOARD`}
        />
      </div>
      <div className="flex flex-wrap sidebar-container relative">
        <DaycareSidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
        <div className="content-sidebar">
          <div className="inner">
            <p
              id="loginerror"
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            {registrations.length === 0 ? (
              <div>
                <h3 className="text-dashboard">
                  Hi, welcome to your dashboard!
                </h3>
                <div className="flex flex-wrap box-dash">
                  <Link
                    to="/waitlists"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div>
                      <div className="border-col flex flex-wrap justify-center items-center">
                        <span>+</span>
                      </div>
                      <p className="text-white">Create a Waitlist</p>
                    </div>
                  </Link>
                  <Link
                    to="/profile"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div className="profile-box">
                      <img src={assetImports.profile} alt="Update Profile" />
                      <p className="text-white">Update Profile</p>
                    </div>
                  </Link>
                  <Link
                    to="/logout"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div>
                      <div className="border-col flex flex-wrap justify-center items-center">
                        <img src={assetImports.logoutSvg} alt="Logout" />
                      </div>
                      <p className="text-white">Logout</p>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <h3 className="text-dashboard">
                  Here is an overview of your rejected list
                </h3>
                <p
                  id="loginerror"
                  className={errMsgUpdate ? "errmsg full" : "offscreen full"}
                  aria-live="assertive"
                >
                  {errMsgUpdate}
                </p>
                <div className="form-div flex flex-wrap justify-start">
                  <div className="div-waitlist bg-white full">
                    <div className="reg-holder">
                      <div className="flex flex-wrap items-center justify-between">
                        <p className="nomargin col-view">
                          <strong>{slug} Rejected List</strong>
                        </p>
                        <p className="nomargin text-center">
                          <strong>Date Accepted</strong>
                        </p>
                        <p className="nomargin col-view flex flex-wrap items-center justify-end">
                          <strong></strong>
                        </p>
                      </div>
                      {registrations.map((registration, index) => (
                        <div
                          className="registered-col flex flex-wrap items-center justify-between"
                          key={index}
                        >
                          <p className="child-info-view col-view">
                            {registration.child.first_name}{" "}
                            {registration.child.last_name}
                          </p>
                          <p className="act-like-no-button nohover button-view text-center italic font-medium nomargin">
                            {formatDate(registration.date_deleted)}
                          </p>
                          <div className="button-holder flex flex-wrap items-center justify-end col-view">
                            <button
                              className="act-like-no-button nohover font-bold button-profile-col"
                              data-tooltip-id="view-profile"
                              data-tooltip-content="View Profile"
                              onClick={() =>
                                openModal(
                                  registration.child.parent_first_name +
                                    " " +
                                    registration.child.parent_last_name,
                                  registration.child.parent_email,
                                  registration.child.parent_phone,
                                  registration.child.first_name +
                                    " " +
                                    registration.child.last_name,
                                  registration.reason_for_rejection
                                )
                              }
                            >
                              View Child Profile
                            </button>
                            <Tooltip
                              id="view-profile"
                              place="top"
                              type="dark"
                              effect="solid"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RejectedListSingle;
