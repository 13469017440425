// MapComponent.js
import React, { useEffect, useState, useRef } from 'react';

const mapApiJs = 'https://maps.googleapis.com/maps/api/js';


const MapComponent = React.memo(({ formData, updateFormData, streetAddress, city, postalCode, province, apiKey }) => {
    const [address, setAddress] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        const initMapScript = async () => {
            try {
                if (!apiKey) {
                    throw new Error('API key is not available.');
                }
    
                if (window.google) {
                    initAutocomplete(); // Call initAutocomplete when Google Maps API is loaded
                    return; // Google Maps API is already loaded
                }
    
                const mapApiJs = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&v=weekly`;
                await loadAsyncScript(mapApiJs);
                initAutocomplete(); // Call initAutocomplete after Google Maps API is loaded
            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }
        };
    
        initMapScript();
    }, [apiKey]);

    const loadAsyncScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = src;
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    };

    const extractAddress = (place) => {
        const address = {
            street_number: "",
            street_address: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            plain() {
                const city = this.city ? this.city + ", " : "";
                const zip = this.zip ? this.zip + ", " : "";
                const state = this.state ? this.state + ", " : "";
                return city + zip + state + this.country;
            }
        }
        if (!Array.isArray(place?.address_components)) {
          return address;
        }
      
        place.address_components.forEach(component => {
          const types = component.types;
          const value = component.long_name;
          if (types.includes("street_number")) {
            address.street_number = value;
          }
    
          if (types.includes("route")) {
            address.street_address = value;
          }
    
          if (types.includes("locality")) {
            address.city = value;
          }
      
          if (types.includes("administrative_area_level_1")) {
            address.state = value;
          }
      
          if (types.includes("postal_code")) {
            address.zip = value;
          }
      
          if (types.includes("country")) {
            address.country = value;
          }
      
        });
      
        return address;
    }

    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        const newAddress = extractAddress(place);
        setAddress(newAddress);
        updateFormData(prevData => ({
            ...prevData,
            street_address: `${newAddress.street_number} ${newAddress.street_address}`,
            city: newAddress.city,
            province: newAddress.state,
            postal_code: newAddress.zip,
        }));
        document.getElementById('city').value = newAddress.city;
        document.getElementById('postal_code').value = newAddress.zip;
        document.getElementById('province').value = newAddress.state;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateFormData({
            ...formData,
            [name]: value.trim()
        });
    }

    const initAutocomplete = () => {
        if (!searchInput.current) return;
    
        const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
        autocomplete.setFields(["address_component", "geometry"]);
        autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
    }

    return (
        <>
            <label htmlFor="address" className="border-primary">Search Location</label>
            <input 
                className='border-primary' 
                type='text'
                ref={searchInput} // Reference for the address search input
                placeholder='Search location....'
                defaultValue={streetAddress} 
            />
            <label htmlFor="address" className="border-primary">City</label>
            <input 
                className='border-primary half' 
                type='text'
                name='city' 
                id='city' 
                defaultValue={city} 
                required 
                placeholder='City'
                readOnly
                onChange={handleChange}
            />
            <label htmlFor="address" className="border-primary">Postal Code</label>
            <input 
                className='border-primary half' 
                type='text'
                name='postal_code' 
                id='postal_code' 
                defaultValue={postalCode}
                required 
                placeholder='Postal Code'
                readOnly
                onChange={handleChange}
            />
            <label htmlFor="address" className="border-primary">Province</label>
            <input 
                className='border-primary' 
                type='text'
                name='province' 
                id='province' 
                defaultValue={province} 
                required 
                placeholder='Province'
                readOnly
                onChange={handleChange}
            />
        </>
    );
});

export default MapComponent;
