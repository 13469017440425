import React, { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import useRefreshToken from "../../../hooks/useRefreshToken";
import getCenterProfile from "../../Profile/centerProfileAPI";
import assetImports from "../../AssetImport/assetImports";
import axiosInstance from "../../../api/axios.js";
import { useNavigate, Link, useParams } from "react-router-dom";
import DaycareSidebar from "../../Sidebar/daycareSidebar.js";
import Select from "react-select";
import styles from "./styles.css";
import {
  GET_WAITLISTS_BY_CENTER,
  DELETE_WAITLIST,
  UPDATE_WAITLIST,
  CREATE_WAITLIST,
} from "../../../apiUrls.js";
import WaitlistSingle from "../../Dashboard/WaitlistSingle.js";
import BannerDaycare from "../../Banner/bannerDaycare.js";
import Preloader from "../../../Preloader.js";

const CreateWaitlist = () => {
  let { slug } = useParams();
  const [dataLoaded, setDataLoaded] = useState(false);
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const [center_name, setCenterName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const [updateForms, setUpdateForms] = useState([
    {
      list_type: "",
      capacity: "",
      min_age: "",
      max_age: "",
      waitlist_info: "",
    },
  ]); // State for update forms
  const [addForms, setAddForms] = useState([
    {
      list_type: "",
      capacity: "",
      min_age: "",
      max_age: "",
      waitlist_info: "",
    },
  ]); // State for add new child forms
  const [loading, setLoading] = useState(false);
  const [errMsgUpdate, setErrMsgWaitlist] = useState("");
  const [successAdd, setSuccessAdd] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [minimizedUpdate, setMinimizedUpdate] = useState([]);
  const [minimizedAdd, setMinimizedAdd] = useState([false]);
  const [listType, setListType] = useState("");

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    // Check if the viewport width is less than a certain value (e.g., 768 pixels for mobile)
    const isMobile = window.innerWidth < 768;

    // Set isOpen to true if it's a mobile viewport
    setIsSidebarOpen(!isMobile);
  }, []); // Only run this effect once when the component mounts

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); // Function to close the sidebar
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getCenterProfile(auth.accessToken);
        const data = response.data;
        if (data && data.center_name) {
          setCenterName(data.center_name);
        } else {
          // console.error('Invalid response format:', data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // console.log('Access token expired or invalid, refreshing token...');
          await refresh();
        } else {
          navigate("/create-center-profile");
          setErrMsg(`Error fetching center profile: ${error.message}`); // Handle other errors
        }
      }
    };
    fetchProfile();
  }, [auth.accessToken]);

  useEffect(() => {
    const getWaitlists = async () => {
      try {
        const waitlists = await axiosInstance.get(GET_WAITLISTS_BY_CENTER, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        });
        if (waitlists.data.length > 0) {
          setAddForms([]);
        }
        setUpdateForms(waitlists.data);
        setMinimizedUpdate(waitlists.data.map(() => true));
      } catch (error) {
        console.error("Error fetching waitlists:", error);
        setErrMsgWaitlist("Error fetching waitlists. Please try again later.");
      }
      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
    };
    getWaitlists();
  }, [auth.accessToken]);

  const deleteWaitlist = async (index, id) => {
    setLoading(true);
    try {
      await axiosInstance.delete(`${DELETE_WAITLIST}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessDelete(true);
        setUpdateForms((prevWaitlist) => {
          const updatedWaitlists = [...prevWaitlist];
          updatedWaitlists.splice(index, 1); // Remove the child at the specified index
          return updatedWaitlists;
        });
      }, 1000);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred";
        setErrMsgWaitlist(`Server Error: ${errorMessage}`);
      } else if (error.request) {
        setErrMsgWaitlist("Network Error: The server did not respond");
      } else {
        setErrMsgWaitlist("An unexpected error occurred");
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSuccessDelete(false);
    }
  };

  const handleChangeUpdate = (index, e) => {
    if (e.target) {
      const { name, value, type, checked } = e.target;
      const newValue = type === "checkbox" ? checked : value;
      setUpdateForms((prevForms) => {
        const updateForms = [...prevForms];
        // Ensure the form data for the given index is initialized
        if (!updateForms[index]) {
          updateForms[index] = {
            list_type: "",
            capacity: "",
            min_age: "",
            max_age: "",
            waitlist_info: "",
          };
        }

        // Update the form data with the new value
        updateForms[index] = {
          ...updateForms[index],
          [name]: newValue,
        };
        return updateForms;
      });
    } else {
      setUpdateForms((prevForms) => {
        const updateForms = [...prevForms];
        if (!updateForms[index]) {
          updateForms[index] = {
            list_type: "",
            capacity: "",
            min_age: "",
            max_age: "",
            waitlist_info: "",
          };
        }

        updateForms[index] = {
          ...updateForms[index],
          list_type: e.value, // Update the list_type field with the selected value
        };
        return updateForms;
      });
    }
  };

  const handleChangeAdd = (index, e) => {
    if (e.target) {
      // Regular input field
      const { name, value } = e.target;
      setAddForms((prevForms) => {
        const updatedForms = [...prevForms];
        if (!updatedForms[index]) {
          updatedForms[index] = {
            list_type: "",
            capacity: "",
            min_age: "",
            max_age: "",
            waitlist_info: "",
          };
        }
        updatedForms[index] = {
          ...updatedForms[index],
          [name]: value,
        };
        return updatedForms;
      });
    } else {
      // React-select
      const { value } = e;
      setAddForms((prevForms) => {
        const updatedForms = [...prevForms];
        if (!updatedForms[index]) {
          updatedForms[index] = {
            list_type: "",
            capacity: "",
            min_age: "",
            max_age: "",
            waitlist_info: "",
          };
        }
        updatedForms[index] = {
          ...updatedForms[index],
          list_type: value,
        };
        return updatedForms;
      });
    }
  };

  const handleFormSubmitUpdate = async (event, index, id, list_type) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axiosInstance.patch(
        UPDATE_WAITLIST,
        { ...updateForms[index] },
        {
          params: {
            id: id,
          },
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setListType(list_type);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessAdd(false);
        setSuccessUpdate(true);
      }, 1000);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setErrMsgWaitlist("Error updating waitlist. Please try again later!");
        } else if (error.response.status === 401) {
          setErrMsgWaitlist("Not Authorized");
        } else {
          setErrMsgWaitlist("Error updating waitlist. Please try again later!");
        }
      } else {
        setErrMsgWaitlist("Error updating waitlist. Please try again later!");
      }
      setListType("");
      setLoading(false);
    }
  };

  const toggleMinimizeUpdate = (index) => {
    setMinimizedUpdate((prevMinimized) => {
      const updatedMinimized = [...prevMinimized];
      updatedMinimized[index] = !updatedMinimized[index];
      return updatedMinimized;
    });
  };

  const handleUpdate = () => {
    setSuccessDelete(false);
    setSuccessAdd(false);
    setSuccessUpdate(false);
    window.location.reload();
  };

  const handleFormSubmitAdd = async (event, index) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axiosInstance.post(
        CREATE_WAITLIST,
        { ...addForms[index] },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessUpdate(false);
        setSuccessAdd(true);
        // setUpdateForms(prevWaitlist => {
        //     const updatedWaitlists = [...prevWaitlist]; // Copy the existing array
        //     const newWaitlist = addForms[index]; // Get the waitlist from the addForms array at the specified index
        //     updatedWaitlists.push(newWaitlist); // Add the new waitlist to the end of the array
        //     return updatedWaitlists;
        // });
      }, 1000);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setErrMsg(error.response.data.errors[0]);
        } else if (error.response.status === 401) {
          setErrMsg("Not Authorized");
        } else {
          setErrMsg("Error adding waitlist");
        }
      } else {
        setErrMsg("Error adding waitlist");
      }
      console.log(error);
      setLoading(false);
    }
  };

  const listTypes = ["Infant", "Toddler", "Preschool"];

  const toggleMinimizeAdd = (index) => {
    setMinimizedAdd((prevMinimized) => {
      const updatedMinimizedAdd = [...prevMinimized];
      updatedMinimizedAdd[index] = !updatedMinimizedAdd[index];
      return updatedMinimizedAdd;
    });
  };

  const handleAddForm = () => {
    setAddForms([
      ...addForms,
      {
        list_type: "",
        capacity: "",
        min_age: "",
        max_age: "",
        waitlist_info: "",
      },
    ]);
  };

  if (slug) {
    return <WaitlistSingle slug={slug} />;
  }

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section>
      {loading && (
        <div className="overlay-loading">
          <img src={assetImports.loadingGif} alt="Loading" />
        </div>
      )}
      <img id="blueBlurbProfile" src={assetImports.blueBlurb} alt="" />
      <img id="greenBlurbProfile" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurbFullProfile" src={assetImports.pinkBlurbFull} alt="" />
      <div>
        <BannerDaycare
          toggleSidebar={toggleSidebar}
          title={`${center_name}'s DASHBOARD`}
        />
      </div>
      <div className="flex flex-wrap sidebar-container relative">
        <DaycareSidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
        <div className="content-sidebar">
          <div className="inner">
            <div className="box-container">
              <div className="relative login-box">
                <p>
                  <strong>
                    Let's help you set up your account - Step 1 of 1
                  </strong>{" "}
                </p>
                <br></br>
                {addForms.length === 0 && (
                  <p>
                    Create a new <strong>Waitlist</strong> by clicking the plus
                    button below
                  </p>
                )}
                {addForms.length > 0 && (
                  <p>
                    Create a new <strong>Waitlist</strong> by entering the
                    information below and click save
                  </p>
                )}
                <br></br>
                <div className="login-form border-primary relative">
                  {successAdd && (
                    <div className="overlay-loading dark">
                      <div className="modal-content flex flex-wrap items-center justify-center">
                        <div className="modal-inner">
                          <img
                            className="success"
                            src={assetImports.successSvg}
                            alt="Successfull Update"
                          />
                          <h1 className="text-center logout-text">
                            Waitlist has been created successfully!
                          </h1>
                          <button
                            className="close-overlay"
                            onClick={handleUpdate}
                          >
                            <span>X</span>
                          </button>
                          <button
                            className="button primary lowercase button-custom"
                            onClick={handleUpdate}
                          >
                            Close
                          </button>
                          <Link
                            className="button primary lowercase button-custom"
                            to="/center-dashboard"
                          >
                            Go To Dashboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}

                  {successUpdate && (
                    <div className="overlay-loading dark">
                      <div className="modal-content flex flex-wrap items-center justify-center">
                        <div className="modal-inner">
                          <img
                            className="success"
                            src={assetImports.successSvg}
                            alt="Successfull Update"
                          />
                          <h1 className="text-center logout-text">
                            {listType} Waitlist has been updated Successfully!
                          </h1>
                          <button
                            className="close-overlay"
                            onClick={handleUpdate}
                          >
                            <span>X</span>
                          </button>
                          <Link
                            className="button primary lowercase button-custom"
                            to={`/registrations/${listType}`}
                          >
                            See Registrations
                          </Link>
                          <Link
                            className="button primary lowercase button-custom"
                            to="/center-dashboard"
                          >
                            Go To Dashboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}

                  {successDelete && (
                    <div className="overlay-loading dark">
                      <div className="modal-content flex flex-wrap items-center justify-center">
                        <div className="modal-inner">
                          <img
                            className="success"
                            src={assetImports.successSvg}
                            alt="Successfull Update"
                          />
                          <h1 className="red text-center logout-text">
                            Waitlist has been deleted Successfully!
                          </h1>
                          <button
                            className="close-overlay"
                            onClick={handleUpdate}
                          >
                            <span>X</span>
                          </button>
                          <button
                            className="button primary lowercase button-custom"
                            onClick={handleUpdate}
                          >
                            Close
                          </button>
                          <Link
                            className="button primary lowercase button-custom"
                            to="/center-dashboard"
                          >
                            Go To Dashboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}

                  <p
                    id="loginerror"
                    className={errMsgUpdate ? "errmsg full" : "offscreen full"}
                    aria-live="assertive"
                  >
                    {errMsgUpdate}
                  </p>
                  {updateForms.map((waitlist, index) => (
                    <div
                      className={`form-div ${
                        minimizedUpdate[index] ? "minimized" : ""
                      }`}
                      key={index}
                    >
                      <div className="flex flex-wrap items-start">
                        <div className="box-child">
                          <div
                            className="bg-secondary w-full div-box"
                            onClick={() => toggleMinimizeUpdate(index)}
                          >
                            <div className="flex flex-wrap justify-between container-inner child-inner items-center">
                              <h4 className="text-white nomargin">
                                {waitlist.list_type} Waitlist - {center_name}{" "}
                              </h4>
                              <div className="flex flex-wrap">
                                <button className="button-box">
                                  <span className="arrow"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                          {!minimizedUpdate[index] && (
                            <form
                              key={index}
                              id="update-waitlist-form"
                              className="child-form"
                              onSubmit={(event) =>
                                handleFormSubmitUpdate(
                                  event,
                                  index,
                                  waitlist.id,
                                  waitlist.list_type
                                )
                              }
                            >
                              <label
                                className="half lab-col"
                                htmlFor="list_type"
                              >
                                <span className="label">List Type</span>
                                <Select
                                  name="list_type"
                                  className="form-select"
                                  value={{
                                    value: waitlist.list_type,
                                    label: waitlist.list_type,
                                  }}
                                  // onChange={(e) => handleChangeUpdate(index, e)}
                                  readOnly
                                  // options={listTypes.map(listType => ({
                                  //     value: listType,
                                  //     label: `${listType}`
                                  // }))}
                                  isMulti={false}
                                  placeholder="List Type"
                                />
                              </label>

                              <label className="half lab-col" htmlFor="center">
                                <span className="label">Center</span>
                                <input
                                  type="text"
                                  value={center_name}
                                  className="half border-secondary"
                                  readOnly
                                  name="center"
                                  id="center"
                                  required
                                  placeholder="Center Name"
                                />
                              </label>

                              <label className="half lab-col" htmlFor="size">
                                <span className="label">Size</span>
                                <input
                                  type="number"
                                  defaultValue={waitlist.size}
                                  onChange={(e) => handleChangeUpdate(index, e)}
                                  className="border-secondary"
                                  readOnly
                                  name="size"
                                  required
                                  placeholder="Size"
                                />
                              </label>

                              <label
                                className="half lab-col"
                                htmlFor="capacity"
                              >
                                <span className="label required">Capacity</span>
                                <input
                                  type="number"
                                  defaultValue={waitlist.capacity}
                                  onChange={(e) => handleChangeUpdate(index, e)}
                                  className="half border-secondary"
                                  name="capacity"
                                  min="0"
                                  required
                                  placeholder="Capacity"
                                />
                              </label>

                              <label className="half lab-col" htmlFor="min_age">
                                <span className="label required">
                                  Minimum Age
                                </span>
                                <input
                                  type="number"
                                  defaultValue={waitlist.min_age}
                                  onChange={(e) => handleChangeUpdate(index, e)}
                                  className="half border-secondary"
                                  name="min_age"
                                  min="0"
                                  required
                                  placeholder="Minimum Age"
                                />
                              </label>

                              <label className="half lab-col" htmlFor="max_age">
                                <span className="label required">
                                  Maximum Age
                                </span>
                                <input
                                  type="number"
                                  defaultValue={waitlist.max_age}
                                  onChange={(e) => handleChangeUpdate(index, e)}
                                  className="half border-secondary"
                                  name="max_age"
                                  min="0"
                                  required
                                  placeholder="Maximum Age"
                                />
                              </label>

                              <label
                                className="lab-col"
                                htmlFor="waitlist_info"
                              >
                                <span className="label">Waitlist Info</span>
                                <textarea
                                  rows="5"
                                  type="textarea"
                                  defaultValue={waitlist.waitlist_info}
                                  onChange={(e) => handleChangeUpdate(index, e)}
                                  className="border-secondary"
                                  name="waitlist_info"
                                  placeholder="Waitlist Info"
                                />
                              </label>
                              <button
                                className="secondary button-custom"
                                type="submit"
                              >
                                UPDATE
                              </button>
                              <Link
                                className="linkwaitlist button-custom primary"
                                to={`/registrations/${waitlist.list_type}`}
                              >
                                See Registrations
                              </Link>
                            </form>
                          )}
                        </div>

                        <button
                          className="button-box delete"
                          onClick={() => deleteWaitlist(index, waitlist.id)}
                        >
                          <span className="text-white">X</span>
                        </button>
                      </div>
                    </div>
                  ))}

                  <p
                    id="loginerror"
                    className={errMsg ? "errmsg full" : "offscreen full"}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                  {addForms.length > 0 &&
                    addForms.length <= 1 &&
                    addForms.map((waitlist, index) => (
                      <div
                        className={`form-div ${
                          minimizedAdd[index] ? "minimized" : ""
                        }`}
                        key={index}
                      >
                        <div className="flex flex-wrap items-start">
                          <div className="box-child">
                            <div
                              className="bg-secondary w-full div-box"
                              onClick={() => toggleMinimizeAdd(index)}
                            >
                              <div className="flex flex-wrap justify-between container-inner child-inner items-center">
                                <h4 className="text-white nomargin">
                                  Waitlist Type
                                </h4>
                                <button className="button-box">
                                  <span className="arrow"></span>
                                </button>
                              </div>
                            </div>
                            {!minimizedAdd[index] && (
                              <form
                                key={index}
                                id="add-waitlist-form"
                                className="waitlist-form"
                                onSubmit={(event) =>
                                  handleFormSubmitAdd(event, index)
                                }
                              >
                                <Select
                                  name="list_type"
                                  className="form-select"
                                  onChange={(e) => handleChangeAdd(index, e)}
                                  options={listTypes.map((listType) => ({
                                    value: listType,
                                    label: `${listType}`,
                                  }))}
                                  isMulti={false}
                                  placeholder="List Type"
                                />
                                <input
                                  type="text"
                                  value={center_name}
                                  className="half border-secondary"
                                  readOnly
                                  name="center"
                                  id="center"
                                  required
                                  placeholder="Center"
                                />
                                <input
                                  type="number"
                                  defaultValue={waitlist.capacity}
                                  onChange={(e) => handleChangeAdd(index, e)}
                                  className="border-secondary"
                                  name="capacity"
                                  min="0"
                                  required
                                  placeholder="Capacity"
                                />
                                <input
                                  type="number"
                                  defaultValue={waitlist.min_age}
                                  onChange={(e) => handleChangeAdd(index, e)}
                                  className="half border-secondary"
                                  name="min_age"
                                  min="0"
                                  required
                                  placeholder="Minimum Age"
                                />
                                <input
                                  type="number"
                                  defaultValue={waitlist.max_age}
                                  onChange={(e) => handleChangeAdd(index, e)}
                                  className="half border-secondary"
                                  name="max_age"
                                  min="0"
                                  required
                                  placeholder="Maximum Age"
                                />
                                <textarea
                                  rows="5"
                                  type="textarea"
                                  defaultValue={waitlist.waitlist_info}
                                  onChange={(e) => handleChangeAdd(index, e)}
                                  className="border-secondary"
                                  name="waitlist_info"
                                  placeholder="Waitlist Info"
                                />
                                <button
                                  className="secondary button-custom"
                                  type="submit"
                                >
                                  SAVE
                                </button>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  {addForms.length === 0 && (
                    <div className="flex flex-wrap justify-end box-child">
                      <button
                        type="button"
                        className="secondary border-secondary act-like-no-button addForm circular nohover"
                        onClick={handleAddForm}
                      >
                        <span>+</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="box-child flex flex-wrap justify-end">
                <Link
                  className="button-custom primary dashboard-link"
                  to="/center-dashboard"
                >
                  Go To Dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateWaitlist;
