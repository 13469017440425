import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axios.js";
import useAuth from "../../hooks/useAuth.js";
import useRefreshToken from "../../hooks/useRefreshToken.js";
import getCenterProfile from "../Profile/centerProfileAPI.js";
import assetImports from "../AssetImport/assetImports.js";
import { useNavigate, Link } from "react-router-dom";
import DaycareSidebar from "../Sidebar/daycareSidebar.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

import {
  ACCEPT_CHILD_REGISTRATION,
  GET_REGISTRATION_IN_WAITLIST,
  GET_WAITLISTS_BY_CENTER,
  DELETE_CHILD_FROM_WAITLIST,
  GIVE_REASON_REJECTION,
  GET_QUALIFIED_REGISTRATIONS,
} from "../../apiUrls.js";
import styles from "./styles.css";
import BannerDaycare from "../Banner/bannerDaycare.js";
import Preloader from "../../Preloader.js";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AllCommunityModules } from "@ag-grid-community/all-modules";

const WaitlistSingle = ({ slug }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const [center_name, setCenterName] = useState("");
  const [registrations, setRegistrations] = useState([]);
  const [originalRegistrations, setOriginalRegistrations] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [nameFull, setNameFull] = useState("");
  const [waitlistIdMain, setWaitlistId] = useState("");
  const [errMsgUpdate, setErrMsgWaitlist] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [inputDate, setInputDate] = useState("");
  // const [registrationsByDate, setRegistrationsByDate] = useState("");

  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 30, 40, 50];

  const generatePDF = () => {
    if (!gridApi) return;

    const columns = colDefs.map((colDef) => ({
      title: colDef.exportTitle || colDef.headerName,
      field: colDef.field,
      nestedKeys: colDef.field ? colDef.field.split(".") : null,
      valueGetter: colDef.valueGetter,
    }));

    const rowData = [];
    gridApi.forEachNode((node) => {
      const row = {};
      columns.forEach((col) => {
        if (col.valueGetter) {
          row[col.title] = col.valueGetter({ data: node.data });
        } else if (col.field) {
          let value = node.data;
          if (col.nestedKeys) {
            col.nestedKeys.forEach((key) => {
              if (value && typeof value === "object") {
                value = value[key];
              } else {
                value = null;
              }
            });
          }
          row[col.title] = value;
        }
      });
      rowData.push(row);
    });

    const doc = new jsPDF({ orientation: "landscape" });
    doc.autoTable({
      head: [columns.map((col) => col.title)],
      body: rowData.map((row) => columns.map((col) => row[col.title])),
    });
    doc.save("table.pdf");
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "P",
      field: "child.position",
      exportTitle: "Position",
      filter: false,
      floatingFilter: false,
      width: 50,
    },
    {
      headerName: "First Name",
      field: "child.first_name",
      filter: true,
      floatingFilter: true,
      width: 175,
    },
    {
      headerName: "Last Name",
      field: "child.last_name",
      filter: true,
      floatingFilter: true,
      width: 175,
    },
    {
      headerName: "Date Registered",
      field: "date_registered",
      cellDataType: "date",
      valueGetter: function (params) {
        // Convert the date string to a Date object
        return new Date(params.data.date_registered);
      },
      cellRenderer: (params) => {
        // Format date for display
        return formatDate(new Date(params.value));
      },
      valueParser: function (params) {
        // Parse the date string into a Date object
        return new Date(params.newValue);
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          // Parse the date string into a Date object
          const dateCellValue = new Date(cellValue);
          // Extract date parts (year, month, day) from the cell value
          const yearCellValue = dateCellValue.getFullYear();
          const monthCellValue = dateCellValue.getMonth();
          const dayCellValue = dateCellValue.getDate();

          // Extract date parts (year, month, day) from the filter value
          const yearFilter = filterLocalDateAtMidnight.getFullYear();
          const monthFilter = filterLocalDateAtMidnight.getMonth();
          const dayFilter = filterLocalDateAtMidnight.getDate();

          // Compare the date parts (year, month, day) to determine if they match
          if (
            yearCellValue === yearFilter &&
            monthCellValue === monthFilter &&
            dayCellValue === dayFilter
          ) {
            return 0;
          } else if (
            yearCellValue < yearFilter ||
            (yearCellValue === yearFilter && monthCellValue < monthFilter) ||
            (yearCellValue === yearFilter &&
              monthCellValue === monthFilter &&
              dayCellValue < dayFilter)
          ) {
            return -1; // cellValue is less than filterValue
          } else {
            return 1; // cellValue is greater than filterValue
          }
        },
      },
      floatingFilter: "true",
      width: 195,
    },
    {
      headerName: "Expected Start Date",
      field: "expected_start_date",
      cellDataType: "date",
      valueGetter: function (params) {
        // Convert the date string to a Date object
        return new Date(params.data.expected_start_date);
      },
      cellRenderer: (params) => {
        // Format date for display
        return formatDate(new Date(params.value));
      },
      valueParser: function (params) {
        // Parse the date string into a Date object
        return new Date(params.newValue);
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          // Parse the date string into a Date object
          const dateCellValue = new Date(cellValue);
          // Extract date parts (year, month, day) from the cell value
          const yearCellValue = dateCellValue.getFullYear();
          const monthCellValue = dateCellValue.getMonth();
          const dayCellValue = dateCellValue.getDate();

          // Extract date parts (year, month, day) from the filter value
          const yearFilter = filterLocalDateAtMidnight.getFullYear();
          const monthFilter = filterLocalDateAtMidnight.getMonth();
          const dayFilter = filterLocalDateAtMidnight.getDate();

          // Compare the date parts (year, month, day) to determine if they match
          if (
            yearCellValue === yearFilter &&
            monthCellValue === monthFilter &&
            dayCellValue === dayFilter
          ) {
            return 0;
          } else if (
            yearCellValue < yearFilter ||
            (yearCellValue === yearFilter && monthCellValue < monthFilter) ||
            (yearCellValue === yearFilter &&
              monthCellValue === monthFilter &&
              dayCellValue < dayFilter)
          ) {
            return -1; // cellValue is less than filterValue
          } else {
            return 1; // cellValue is greater than filterValue
          }
        },
      },
      floatingFilter: "true",
      width: 195,
    },
    {
      headerName: "Eligible?",
      field: "qualified",
      filter: true,
      floatingFilter: true,
      resizable: true,
      width: 117,
    },
    {
      headerName: "Parent Name",
      field: "child.parent.first_name",
      valueGetter: function (params) {
        return (
          params.data.child.parent.first_name +
          " " +
          params.data.child.parent.last_name
        );
      },
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Email",
      field: "child.parent.email",
      filter: true,
      floatingFilter: true,
      hide: true,
    },
    {
      headerName: "Phone",
      field: "child.parent.phone",
      filter: true,
      floatingFilter: true,
      hide: true,
    },
    {
      headerName: "Action",
      cellRenderer: (params) => (
        <div className="flex flex-wrap h-full justify-end items-center">
          <button
            className="act-like-no-button nohover info"
            data-tooltip-id={`info-tooltip-${params.data.reg_id}`}
            data-tooltip-content="Info"
            onClick={() =>
              openModal(
                params.data.child.parent.first_name +
                  " " +
                  params.data.child.parent.last_name,
                params.data.child.parent.email,
                params.data.child.parent.phone,
                params.data.child.first_name +
                  " " +
                  params.data.child.last_name,
                params.data.expected_start_date
              )
            }
          >
            {" "}
            <img className="icon-info" src={assetImports.iconSvg} alt="" />
          </button>
          {params.data.qualified == true &&
            params.data.child.position === 1 && (
              <>
                <button
                  className="act-like-no-button nohover accept"
                  data-tooltip-id={`accept-tooltip-${params.data.reg_id}`}
                  data-tooltip-content="Accept Registration"
                  onClick={() =>
                    acceptRegistration(
                      params.data.reg_id,
                      `${params.data.child.first_name} ${params.data.child.last_name}`
                    )
                  }
                >
                  <span className="checkmark draw"></span>
                </button>
                {/* <Tooltip
                  id={`accept-tooltip-${params.data.reg_id}`}
                  className="tool-tip"
                  place="top"
                  type="dark"
                  effect="solid"
                /> */}
              </>
            )}
          <button
            className="act-like-no-button nohover delete"
            data-tooltip-id={`delete-tooltip-${params.data.reg_id}`}
            data-tooltip-content="Delete Registration"
            onClick={() =>
              openDeleteModal(
                params.data.reg_id,
                `${params.data.child.first_name} ${params.data.child.last_name}`
              )
            }
          ></button>
          {/* <Tooltip
            id={`delete-tooltip-${params.data.reg_id}`}
            place="top"
            type="dark"
            effect="solid"
          /> */}
        </div>
      ),
      width: 115,
    },
  ]);

  const gridOptions = {
    // Enable the column tool panel
    modules: AllCommunityModules,
    onGridReady: (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
    },
    exportParams: {
      // Process cell callback for CSV export
      processCellCallback: (params) => {
        const lastColumn = params.columnApi.getAllColumns().slice(-1)[0];
        // Check if the column is the last column ('Action' column)
        if (params.column === lastColumn) {
          return null; // Return null to hide the cell
        }
        return params.value; // Return the original cell value for other columns
      },
    },
    columnDefs: colDefs,
  };

  const handleExportToCsv = () => {
    if (gridApi) {
      // Store original column definitions
      const originalColumnDefs = gridApi.getColumnDefs();

      // Temporarily modify column definitions for export
      const modifiedColumnDefs = originalColumnDefs.map((colDef) => ({
        ...colDef,
        headerName: colDef.exportTitle || colDef.headerName, // Use exportTitle if available, otherwise fallback to headerName
      }));

      // Set modified column definitions for export
      gridApi.setColumnDefs(modifiedColumnDefs);

      // Export CSV
      const params = {
        fileName: "data.csv",
        columnKeys: originalColumnDefs.map((colDef) => colDef.field),
      };
      gridApi.exportDataAsCsv(params, {
        onlySelected: false,
        skipHeader: false,
        skipFooters: true,
        skipGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
      });

      // Restore original column definitions
      gridApi.setColumnDefs(originalColumnDefs);
    }
  };

  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Function to get ordinal suffix for day
    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return `${months[monthIndex]} ${day}${getOrdinalSuffix(day)}, ${year}`;
  }

  const rowClassRules = {
    // apply red to Ford cars
    "rag-red": (params) => params.data.qualified === false,
    "cursor-pointer": () => true,
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [customValue, setCustomValue] = useState("");

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    // Check if the viewport width is less than a certain value (e.g., 768 pixels for mobile)
    const isMobile = window.innerWidth < 768;

    // Set isOpen to true if it's a mobile viewport
    setIsSidebarOpen(!isMobile);
  }, []); // Only run this effect once when the component mounts

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); // Function to close the sidebar
  };

  const options = [
    { value: "option1", label: "Over the age in this waitlist" },
    { value: "option2", label: "Exceed the date expected Limit" },
    { value: "option3", label: "Too Young to start in this center" },
    { value: "option4", label: "Center is currently under renovation" },
    { value: "Other", label: "Other" },
  ];

  const handleCustomValueChange = (e) => {
    // Update custom value when the user types in the input field
    const customValue = e.target.value;
    setCustomValue(customValue);
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value; // Get the value of the selected option
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    ); // Find the corresponding option object
    const selectedLabel = selectedOption ? selectedOption.label : ""; // Extract the label from the option object
    setSelectedOption(selectedLabel);
    // Reset custom value when an option is selected
  };

  const openModal = (
    parentName,
    parentEmail,
    parentPhone,
    childName,
    expectedStartDate
  ) => {
    setModalData({
      parentName,
      parentEmail,
      parentPhone,
      childName,
      expectedStartDate,
    });
    setIsModalOpen(true);
  };

  const openDeleteModal = (registrationId, childName) => {
    setDeleteModalData({
      registrationId,
      childName,
    });
    setIsDeleteModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
    // You can also clear modalData here if needed
  };

  const handleChangeDateRegistrations = (event) => {
    const newDate = event.target.value;
    setInputDate(newDate);
    // Check if the input date is empty, if so, reset registrations
    if (!newDate) {
      setRegistrations(originalRegistrations);
    }
  };

  const handleSubmitDateRegistrations = async (availableDate, waitlistId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(GET_QUALIFIED_REGISTRATIONS, {
        params: {
          available_date: availableDate,
          waitlist_id: waitlistId,
        },
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      setTimeout(() => {
        setLoading(false);
        setRegistrations(response.data.data.registrations);
      }, 1000); // Optionally, update state with response data
    } catch (error) {
      console.error("Error:", error);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleRowClick = (event) => {
    if (
      event.event.target.classList.contains("delete") ||
      event.event.target.classList.contains("accept")
    ) {
      return; // Do nothing if the delete button was clicked
    }
    const rowData = event.data;

    // Add your logic for handling row click here, such as opening a modal
    openModal(
      rowData.child.parent.first_name + " " + rowData.child.parent.last_name,
      rowData.child.parent.email,
      rowData.child.parent.phone,
      rowData.child.first_name + " " + rowData.child.last_name,
      rowData.expected_start_date
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const waitlistsResponse = await axiosInstance.get(
          `${GET_WAITLISTS_BY_CENTER}`,
          {
            headers: {
              Authorization: `Bearer ${auth.accessToken}`,
            },
          }
        );
        const matchingWaitlist = waitlistsResponse.data.find(
          (waitlist) => waitlist.list_type === slug
        );
        if (matchingWaitlist) {
          const { id, size } = matchingWaitlist;
          setWaitlistId(id);
          const registrationsResponse = await axiosInstance.get(
            `${GET_REGISTRATION_IN_WAITLIST}?id=${id}`,
            {
              headers: {
                Authorization: `Bearer ${auth.accessToken}`,
              },
            }
          );
          const registrationsData =
            registrationsResponse.data.data.registrations.map(
              (registration) => ({
                ...registration,
                size: size,
              })
            );
          setRegistrations(registrationsData);
          setOriginalRegistrations(registrationsData);
        } else {
          // console.log(`No waitlist found with list_type ${slug}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrMsgWaitlist("Error fetching data. Please try again later.");
      }
      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
    };

    fetchData();
  }, [auth.accessToken, slug]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getCenterProfile(auth.accessToken);
        const data = response.data;
        if (data && data.center_name) {
          setCenterName(data.center_name);
        } else {
          console.error("Invalid response format:", data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Access token expired or invalid, refreshing token...");
          await refresh();
        } else {
          navigate("/create-center-profile");
          setErrMsg(`Error fetching center profile: ${error.message}`); // Handle other errors
        }
      }
    };
    fetchProfile();
  }, [auth.accessToken]);

  const handleFormDelete = async (event, regID, childName) => {
    event.preventDefault();
    setLoading(true);
    const valueToSend =
      selectedOption === "Other" ? customValue : selectedOption;
    try {
      await axiosInstance.patch(
        GIVE_REASON_REJECTION,
        { reason_for_deletion: valueToSend },
        {
          params: {
            id: regID,
          },
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      await axiosInstance.delete(DELETE_CHILD_FROM_WAITLIST, {
        params: {
          id: regID,
        },
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      setNameFull(childName);
      setTimeout(() => {
        setIsDeleteModalOpen(false);
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessDelete(true);
      }, 1000);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred";
        setErrMsgWaitlist(`Server Error: ${errorMessage}`);
      } else if (error.request) {
        setErrMsgWaitlist("Network Error: The server did not respond");
      } else {
        setErrMsgWaitlist("An unexpected error occurred");
      }
      setNameFull("");
      setTimeout(() => {
        setIsDeleteModalOpen(true);
        setLoading(false);
      }, 1000);
      setSuccessDelete(false);
    }
  };

  const acceptRegistration = async (listID, childName) => {
    setLoading(true);
    try {
      await axiosInstance.post(
        `${ACCEPT_CHILD_REGISTRATION}`,
        { registration: listID },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setNameFull(childName);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessUpdate(true);
      }, 1000);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred";
        if (error.response.status === 400) {
          setErrMsgWaitlist(error.response.data.errors);
        } else {
          setErrMsgWaitlist(`Server Error: ${errorMessage}`);
        }
      } else if (error.request) {
        setErrMsgWaitlist("Network Error: The server did not respond");
      } else {
        setErrMsgWaitlist("An unexpected error occurred");
      }
      setNameFull("");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSuccessUpdate(false);
    }
  };

  const handleUpdate = () => {
    setSuccessDelete(false);
    setSuccessUpdate(false);
    window.location.reload();
  };

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section>
      {isDeleteModalOpen && (
        <div className="overlay-loading dark">
          <div className="modal-content flex flex-wrap items-center justify-center rounded-edge">
            <div className="modal-inner bigger full">
              <img className="success" src={assetImports.deleteSvg} alt="" />
              <form
                id="delete-form"
                className="items-start bg-white"
                onSubmit={(event) =>
                  handleFormDelete(
                    event,
                    deleteModalData.registrationId,
                    deleteModalData.childName
                  )
                }
              >
                <div className="flex flex-wrap justify-between items-start full">
                  <p
                    id="loginerror"
                    className={errMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                  <h3 className="font-bold full text-left">
                    Reject and Remove {deleteModalData.childName} from this
                    waitlist!
                  </h3>
                  <p className="full text-left">
                    Please choose a suitable reason for rejecting this child
                  </p>
                  <div className="radio-col-field">
                    {options.map((option) => (
                      <label key={option.value} className="radio-container">
                        {option.label}
                        <input
                          type="radio"
                          value={option.value}
                          checked={selectedOption === option.label}
                          onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    ))}
                    {/* Render input field if custom option is selected */}
                    {selectedOption === "Other" ? (
                      <textarea
                        rows="5"
                        type="text"
                        value={customValue}
                        onChange={handleCustomValueChange}
                        placeholder="Enter Custom Message"
                      />
                    ) : null}
                  </div>
                  <button className="button-custom primary" type="submit">
                    SUBMIT
                  </button>
                </div>
              </form>
              <button className="close-overlay" onClick={closeModal}>
                <span>X</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="overlay-loading dark">
          <div className="modal-content flex flex-wrap items-center justify-center rounded-edge">
            <div className="modal-inner full">
              <div className="view-profile-list flex flex-wrap items-center">
                <div className="profile-content">
                  <p>
                    <span className="font-semibold">
                      Child Name:{" "}
                      <span className="font-normal">{modalData.childName}</span>
                    </span>
                  </p>
                  <p>
                    <span className="font-semibold">
                      Expected Start Date:{" "}
                      <span className="font-normal">
                        {modalData.expectedStartDate}
                      </span>
                    </span>
                  </p>
                  <p>
                    <span className="font-semibold">
                      Parent:{" "}
                      <span className="font-normal">
                        {modalData.parentName}
                      </span>
                    </span>
                  </p>
                  <p>
                    <span className="font-semibold">Phone: </span>
                    <a
                      className="black font-normal"
                      target="_blank"
                      href={`tel:${modalData.parentPhone}`}
                    >
                      {modalData.parentPhone}
                    </a>
                  </p>
                  <p>
                    <span className="font-semibold">Email: </span>
                    <a
                      className="black font-normal"
                      target="_blank"
                      href={`mailto:${modalData.parentEmail}`}
                    >
                      {modalData.parentEmail}
                    </a>
                  </p>
                  <a
                    className="primary button-custom"
                    href={`mailto:${modalData.parentEmail}`}
                    target="_blank"
                  >
                    Send Message
                  </a>
                </div>
                <div>
                  <div className="border-pink bg-pink avatar-div items-center justify-center flex flex-wrap mx-auto">
                    <img src={assetImports.avatar} alt="" />
                  </div>
                </div>
              </div>
              <button className="close-overlay" onClick={closeModal}>
                <span>X</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="overlay-loading">
          <img src={assetImports.loadingGif} alt="Loading" />
        </div>
      )}
      <img id="blueBlurbProfile" src={assetImports.blueBlurb} alt="" />
      <img id="greenBlurbProfile" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurbFullProfile" src={assetImports.pinkBlurbFull} alt="" />
      <div>
        <BannerDaycare
          toggleSidebar={toggleSidebar}
          title={`${center_name}'s DASHBOARD`}
        />
      </div>
      <div className="flex flex-wrap sidebar-container relative">
        <DaycareSidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
        <div className="content-sidebar">
          <div className="inner max-full">
            {successUpdate && (
              <div className="overlay-loading dark">
                <div className="modal-content flex flex-wrap items-center justify-center">
                  <div className="modal-inner bigger">
                    <img
                      className="success"
                      src={assetImports.successSvg}
                      alt="Successfull Update"
                    />
                    <h2 className="text-center logout-text font-bold">
                      {nameFull} has been accepted into your day care waitlist
                    </h2>
                    <p>
                      We are pleased to inform you that this child has been
                      successfully enrolled in your daycare facility.
                      Accordingly, the child's status will be updated from the
                      waitlist to the accepted list. We appreciate your
                      attention to this update.
                    </p>
                    <button className="close-overlay" onClick={handleUpdate}>
                      <span>X</span>
                    </button>
                    <Link
                      className="button-custom primary lowercase"
                      to="/accepted-list"
                    >
                      Go To Accepted List
                    </Link>
                    <button
                      className="button-custom primary lowercase"
                      onClick={handleUpdate}
                    >
                      <span>Go To Dashboard</span>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {successDelete && (
              <div className="overlay-loading dark">
                <div className="modal-content flex flex-wrap items-center justify-center">
                  <div className="modal-inner bigger">
                    <img
                      className="success"
                      src={assetImports.successSvg}
                      alt="Successfull Update"
                    />
                    <h2 className="red text-center logout-text font-bold">
                      {nameFull} has been deleted successfully from your day
                      care waitlist!
                    </h2>
                    <p>
                      We are pleased to inform you that this child has been
                      successfully deleted from your daycare waitlist.
                      Accordingly, the child's status will be updated from the
                      waitlist to the rejected list. We appreciate your
                      attention to this update.
                    </p>
                    <button className="close-overlay" onClick={handleUpdate}>
                      <span>X</span>
                    </button>
                    <Link
                      className="button-custom primary lowercase"
                      to="/rejected-list"
                    >
                      Go To Rejected List
                    </Link>
                    <button
                      className="button-custom primary lowercase"
                      onClick={handleUpdate}
                    >
                      <span>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            )}

            <p
              id="loginerror"
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            {registrations.length === 0 ? (
              <div>
                <h3 className="text-dashboard">
                  Hi, welcome to your dashboard!
                </h3>
                <div className="flex flex-wrap box-dash">
                  <Link
                    to="/waitlists"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div>
                      <div className="border-col flex flex-wrap justify-center items-center">
                        <span>+</span>
                      </div>
                      <p className="text-white">Create a Waitlist</p>
                    </div>
                  </Link>
                  <Link
                    to="/profile"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div className="profile-box">
                      <img src={assetImports.profile} alt="Update Profile" />
                      <p className="text-white">Update Profile</p>
                    </div>
                  </Link>
                  <Link
                    to="/logout"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div>
                      <div className="border-col flex flex-wrap justify-center items-center">
                        <img src={assetImports.logoutSvg} alt="Logout" />
                      </div>
                      <p className="text-white">Logout</p>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex flex-wrap justify-between items-center box-dashboard">
                  <h3 className="text-dashboard nom-b">
                    Here is an overview of the <strong>registrations</strong> on
                    your {slug} waitlist
                  </h3>

                  <div className="flex flex-wrap justify-between items-center">
                    <button
                      className="ag-button act-like-no-button cursor-pointer"
                      onClick={handleExportToCsv}
                    >
                      <img
                        className="csv"
                        src={assetImports.downloadSvg}
                        alt=""
                      />
                    </button>
                    <button
                      className="ag-button  act-like-no-button cursor-pointer"
                      onClick={generatePDF}
                    >
                      <img className="pdf" src={assetImports.printSvg} alt="" />
                    </button>
                  </div>
                </div>
                <div>
                  <div className="datePicker">
                    <p className="primary font-semibold date-para">
                      Please use the <strong>date picker</strong> to get all
                      eligible registrations by a specific date:
                    </p>
                    <input
                      type="date"
                      required
                      value={inputDate}
                      onChange={handleChangeDateRegistrations}
                    />
                    <button
                      onClick={() =>
                        handleSubmitDateRegistrations(inputDate, waitlistIdMain)
                      }
                      className="button-custom primary"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <p
                  id="loginerror"
                  className={errMsgUpdate ? "errmsg full" : "offscreen full"}
                  aria-live="assertive"
                >
                  {errMsgUpdate}
                </p>
                <div
                  className="ag-theme-alpine col-cc-ag" // applying the grid theme
                  style={{ height: 700 }} // the grid will fill the size of the parent container
                >
                  <AgGridReact
                    gridOptions={gridOptions}
                    rowData={registrations}
                    columnDefs={colDefs}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    rowClassRules={rowClassRules}
                    onRowClicked={handleRowClick}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WaitlistSingle;
