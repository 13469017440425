import axiosInstance from "../../api/axios";
import React, { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import assetImports from "../AssetImport/assetImports";
import styles from "./styles.css";
import { LOGIN_URL } from "../../apiUrls";
import getCurrentProfile from "../Profile/profileAPI";
import getCenterProfile from "../Profile/centerProfileAPI";
import useRefreshToken from "../../hooks/useRefreshToken";
import { jwtDecode } from "jwt-decode";
import Preloader from "../../Preloader";

function Login() {
  const [dataLoadedHome, setDataLoadedHome] = useState(false);
  const { setAuth, persist, setPersist, auth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCareCenter, setIsCareCenter] = useState(false);
  const [isParent, setIsParent] = useState(false);

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (auth.accessToken) {
      const decodedToken = jwtDecode(auth.accessToken);
      setIsCareCenter(decodedToken.is_care_center === false);
      setIsParent(decodedToken.is_parent === true);
    }
  }, [auth.accessToken]);

  useEffect(() => {
    if (auth.accessToken && isParent) {
      const fetchProfile = async () => {
        try {
          if (isParent) {
            const response = await getCurrentProfile(auth.accessToken);
            if (response.data) {
              // User profile exists, redirect to dashboard
              history("/dashboard");
            } else {
              // User profile does not exist, redirect to create profile
              history("/create-parent-profile");
            }
          }
        } catch (error) {
          console.error("Error checking profile:", error);
          // Redirect to create profile in case of error
          if (isParent) {
            history("/create-parent-profile");
          }
        }
      };
      fetchProfile();
    }
    setTimeout(() => {
      setDataLoadedHome(true);
    }, 1000);
  }, [auth.accessToken, history, isParent]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value.trim().toLowerCase());
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const fetchProfile = async (accessToken) => {
    try {
      const response = await getCurrentProfile(accessToken);
      if (response.data) {
        // User profile exists, redirect to dashboard
        history("/dashboard");
      } else {
        // User profile does not exist, redirect to create profile
        history("/create-parent-profile");
      }
    } catch (error) {
      console.error("Error checking profile:", error);
      // Redirect to create profile in case of error
      history("/create-parent-profile");
    }
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  if (!dataLoadedHome) {
    return <Preloader />;
  }

  return (
    <>
      <div className="overlay-select bg-primary">
        <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
        <img id="purpleBlurb" src={assetImports.greenBlurb} alt="" />
        <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
        <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
        <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
        <div>
          <div className="image-holder">
            <img
              className="logo-image"
              src={assetImports.logoWhite}
              alt="Logo"
            />
          </div>
          <div className="flex flex-wrap select-holder">
            <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
            <div className="box bg-white border-secondary">
              <div className="inner text-center">
                <img
                  className="icon"
                  src={assetImports.parentIcon}
                  alt="Logo"
                />
                <h1 className="font-lguy primary text-center">
                  Are you a parent?
                </h1>
                <Link className="button-custom primary" to="/login">
                  Parent Portal
                </Link>
              </div>
            </div>
            <div className="box bg-secondary border-white">
              <div className="inner text-center">
                <img
                  className="icon"
                  src={assetImports.carecenter}
                  alt="Logo"
                />
                <h1 className="font-lguy text-white text-center">
                  Are you a Care Center?
                </h1>
                <Link className="button-custom pink small" to="/center-login">
                  {" "}
                  CENTER PORTAL
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
