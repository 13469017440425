import { useNavigate, Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import Banner from "../Banner/banner";
import ProfileBar from "../NameProfileBar/nameprofilebar";
import useAuth from "../../hooks/useAuth";
import axiosInstance from "../../api/axios";
import assetImports from "../AssetImport/assetImports";
import styles from "./styles.css";
import {
  AUTHENTICATED_CHANGE_PASSWORD_URL,
  UPDATE_PARENT_PROFILE_URL,
} from "../../apiUrls";
import getCurrentProfile from "./profileAPI";
import useRefreshToken from "../../hooks/useRefreshToken";
import Sidebar from "../Sidebar/sidebar";
import MapComponent from "../MapComponent";
import ApiKeyFetcher from "../ApiKeyFetcher";
import Preloader from "../../Preloader";

const Profile = () => {
  const refresh = useRefreshToken();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { auth } = useAuth();
  const [formData, updateFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [first_name, setFirstName] = useState();
  const [errMsg, setErrMsg] = useState("");
  const errRef = useRef();
  const navigate = useNavigate();
  const [successPassword, setSuccessPassword] = useState(false);
  const [old_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setConfirmPassword] = useState("");
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); // Function to close the sidebar
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getCurrentProfile(auth.accessToken);
        setFirstName(response.data.first_name);
        // Set the fetched data in the form data state
        const {
          city,
          phone,
          postal_code,
          province,
          street_address,
          first_name,
          last_name,
          email,
        } = response.data;

        // Set the fetched data in the form data state
        updateFormData((prevFormData) => ({
          ...prevFormData,
          city: city || "",
          phone: phone || "",
          postal_code: postal_code || "",
          street_address: street_address || "",
          province: province || "",
          first_name: first_name || "",
          last_name: last_name || "",
          email: email || "",
        }));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Access token expired or invalid, refreshing token...");
          await refresh();
        } else {
          setErrMsg("Error fetching current profile. Please try again later."); // Handle other errors
        }
        setErrMsg("Error fetching current profile:", error);
        navigate("/login");
      }
      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
    };
    fetchProfile();
  }, [auth.accessToken]);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const result = await axiosInstance.patch(
        UPDATE_PARENT_PROFILE_URL,
        formData,
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessUpdate(true);
      }, 1000);
      setFirstName(result.data.first_name);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setErrMsg("Error creating or updating parent profile");
        } else if (error.response.status === 401) {
          setErrMsg("Not Auhorized");
        } else {
          setErrMsg("Error creating or updating parent profile");
        }
      } else {
        setErrMsg("Error creating or updating parent profile");
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSuccessUpdate(false);
      errRef.current.focus();
    }
  };

  const handleChangeReset = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "password2") {
      setConfirmPassword(value);
    } else if (name === "old_password") {
      setOldPassword(value);
    }
  };

  const handleFormSubmitReset = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (password2 === "" || password === "" || old_password === "") {
      setLoading(false);
      setErrMsg("Please fill out all fields.");
      return;
    }
    if (password !== password2) {
      setLoading(false);
      setErrMsg("Password and confirm password do not match.");
      return;
    }

    try {
      const response = await axiosInstance.post(
        AUTHENTICATED_CHANGE_PASSWORD_URL,
        { old_password: old_password, password, password2 },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setPassword("");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessPassword(true);
      }, 1000);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          setErrMsg("The existing password you entered is incorrect!");
        } else if (error.response.status === 401) {
          setErrMsg("Invalid Credentials");
        } else {
          setErrMsg("Unable to change password, please try again later");
        }
      } else {
        setErrMsg("Network Error");
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSuccessPassword(false);
    }
  };

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section id="profilePage">
      {loading && (
        <div className="overlay-loading">
          <img src={assetImports.loadingGif} alt="Loading" />
        </div>
      )}
      <img id="blueBlurbProfile" src={assetImports.blueBlurb} alt="" />
      <img id="greenBlurbProfile" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurbFullProfile" src={assetImports.pinkBlurbFull} alt="" />
      <div>
        <Banner
          toggleSidebar={toggleSidebar}
          title={`${first_name}'s PROFILE`}
        />
      </div>
      <div className="flex flex-wrap sidebar-container relative">
        <Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
        <div className="content-sidebar">
          <div className="inner">
            <div>
              <ProfileBar
                name={`Hi ${first_name}`}
                text="Welcome to your profile"
                img={assetImports.purpleArrow}
              />
            </div>
            <div className="border-pink bg-pink avatar-div mobile-avatar items-center justify-center flex flex-wrap mx-auto show-for-mobile">
              <img src={assetImports.avatar} alt="" />
            </div>
            <div className="profile-container">
              <div className="relative login-box">
                <div className="login-form border-primary relative">
                  {successUpdate && (
                    <div className="overlay-loading dark">
                      <div className="modal-content flex flex-wrap items-center justify-center">
                        <div className="modal-inner">
                          <img
                            className="success"
                            src={assetImports.successSvg}
                            alt="Successfull Update"
                          />
                          <h1 className="text-center logout-text">
                            Your Profile has successfully been updated!
                          </h1>
                          <button
                            className="close-overlay"
                            onClick={() => setSuccessUpdate(false)}
                          >
                            <span>X</span>
                          </button>
                          <button
                            className="button-custom primary"
                            onClick={() => setSuccessUpdate(false)}
                          >
                            Close
                          </button>
                          <Link
                            className="button-custom primary"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  {successPassword && (
                    <div className="overlay-loading dark">
                      <div className="modal-content flex flex-wrap items-center justify-center">
                        <div className="modal-inner">
                          <img
                            className="success"
                            src={assetImports.successSvg}
                            alt="Successfull Update"
                          />
                          <h1 className="text-center logout-text">
                            Password has been successfully reset
                          </h1>
                          <button
                            className="close-overlay"
                            onClick={() => setSuccessPassword(false)}
                          >
                            <span>X</span>
                          </button>
                          <button
                            className="button-custom primary"
                            onClick={() => setSuccessPassword(false)}
                          >
                            Close
                          </button>
                          <Link
                            className="button-custom primary"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  {!successUpdate && (
                    <>
                      <form
                        id="update-profile-form"
                        className="form-border form-profile bg-white"
                        onSubmit={handleFormSubmit}
                      >
                        <h3 className="font-bold full title-text">
                          PROFILE INFO
                        </h3>
                        <div className="flex flex-wrap col-reverse-block">
                          <div className="form-box flex flex-wrap justify-center items-start">
                            <p
                              id="loginerror"
                              ref={errRef}
                              className={
                                errMsg ? "errmsg full" : "offscreen full"
                              }
                              aria-live="assertive"
                            >
                              {errMsg}
                            </p>
                            <label
                              htmlFor="first_name"
                              className="border-primary"
                            >
                              First Name
                            </label>
                            <input
                              className="border-primary half"
                              type="text"
                              name="first_name"
                              id="first_name"
                              defaultValue={formData.first_name}
                              required
                              placeholder="First Name"
                              onChange={handleChange}
                            />
                            <label
                              htmlFor="last_name"
                              className="border-primary"
                            >
                              Last Name
                            </label>
                            <input
                              className="border-primary half"
                              type="text"
                              name="last_name"
                              id="last_name"
                              defaultValue={formData.last_name}
                              required
                              placeholder="Last Name"
                              onChange={handleChange}
                            />
                            <label htmlFor="phone" className="border-primary">
                              Phone Number
                            </label>
                            <input
                              className="border-primary half"
                              type="tel"
                              name="phone"
                              defaultValue={formData.phone}
                              required
                              placeholder="Phone Number"
                              onChange={handleChange}
                            />
                            <label htmlFor="email" className="border-primary">
                              Email Address
                            </label>
                            <input
                              className="border-primary half"
                              type="email"
                              name="email"
                              id="email"
                              defaultValue={formData.email}
                              required
                              placeholder="Email"
                              readOnly
                            />
                            <ApiKeyFetcher>
                              <MapComponent
                                formData={formData}
                                updateFormData={updateFormData}
                                streetAddress={formData.street_address}
                                city={formData.city}
                                postalCode={formData.postal_code}
                                province={formData.province}
                              />
                            </ApiKeyFetcher>
                            <button
                              className="primary button-custom right-align"
                              type="submit"
                            >
                              UPDATE PROFILE
                            </button>
                          </div>
                          <div className="button-box">
                            <div className="border-pink avatar-div items-center justify-center flex flex-wrap mx-auto hide-for-mobile">
                              <img src={assetImports.avatar} alt="" />
                            </div>
                          </div>
                        </div>
                      </form>
                      <form
                        id="reset-password-form-auth"
                        className="items-start form-border form-profile bg-white"
                        onSubmit={handleFormSubmitReset}
                      >
                        <h3 className="font-bold full title-text">SECURITY</h3>
                        <div className="flex flex-wrap justify-between items-start full">
                          <p
                            id="loginerror"
                            ref={errRef}
                            className={errMsg ? "errmsg" : "offscreen"}
                            aria-live="assertive"
                          >
                            {errMsg}
                          </p>
                          <div className="col-field">
                            <label
                              htmlFor="old_password"
                              className="border-primary"
                            >
                              Old Password
                            </label>
                            <input
                              className="border-primary half"
                              placeholder="Old Password"
                              type="password"
                              name="old_password"
                              id="old_password"
                              onChange={handleChangeReset}
                              required
                            />
                          </div>
                          <div className="col-field">
                            <label
                              htmlFor="password"
                              className="border-primary"
                            >
                              Password
                            </label>
                            <input
                              className="border-primary half"
                              placeholder="Password"
                              type="password"
                              name="password"
                              id="password"
                              onChange={handleChangeReset}
                              required
                            />
                          </div>
                          <div className="col-field">
                            <label
                              htmlFor="password2"
                              className="border-primary"
                            >
                              Confirm Password
                            </label>
                            <input
                              className="border-primary half"
                              placeholder="Confirm Password"
                              type="password"
                              name="password2"
                              id="password2"
                              onChange={handleChangeReset}
                              required
                            />
                          </div>
                          <button
                            className="button-custom primary right-align"
                            type="submit"
                          >
                            CHANGE PASSWORD
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
