import axiosInstance from "../../api/axios";
import React, { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import assetImports from "../AssetImport/assetImports";
import styles from "./styles.css";
import { LOGIN_URL, RESEND_EMAIL_URL } from "../../apiUrls";
import getCurrentProfile from "../Profile/profileAPI";
import { jwtDecode } from "jwt-decode";

function Login() {
  const { setAuth, persist, setPersist, auth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCareCenter, setIsCareCenter] = useState(false);
  const [isParent, setIsParent] = useState(false);

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (auth.accessToken) {
      const decodedToken = jwtDecode(auth.accessToken);
      setIsCareCenter(decodedToken.is_care_center === false);
      setIsParent(decodedToken.is_parent === true);
    }
  }, [auth.accessToken]);

  useEffect(() => {
    if (auth.accessToken && isParent) {
      const fetchProfile = async () => {
        try {
          if (isParent) {
            const response = await getCurrentProfile(auth.accessToken);
            if (response.data) {
              // User profile exists, redirect to dashboard
              history("/dashboard");
            } else {
              // User profile does not exist, redirect to create profile
              history("/create-parent-profile");
            }
          }
        } catch (error) {
          console.error("Error checking profile:", error);
          // Redirect to create profile in case of error
          if (isParent) {
            history("/create-parent-profile");
          }
        }
      };
      fetchProfile();
    }
  }, [auth.accessToken, history, isParent]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value.trim().toLowerCase());
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const fetchProfile = async (accessToken) => {
    try {
      const response = await getCurrentProfile(accessToken);
      if (response.data) {
        // User profile exists, redirect to dashboard
        history("/dashboard");
      } else {
        // User profile does not exist, redirect to create profile
        history("/create-parent-profile");
      }
    } catch (error) {
      console.error("Error checking profile:", error);
      // Redirect to create profile in case of error
      history("/create-parent-profile");
    }
  };

  const sendEmail = async (emailAddress) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(RESEND_EMAIL_URL, {
        email: emailAddress,
      });
      setLoading(false);
    } catch (error) {
      if (error.response) {
        setErrMsg(error.response);
      } else {
        setErrMsg("Network Error");
      }
      setLoading(false);
      errRef.current.focus();
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (email.trim() === "" || password === "") {
      setErrMsg("Please fill out both email and password fields.");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.post(LOGIN_URL, { email, password });
      const accessToken = response?.data?.access;
      const refreshToken = response?.data?.refresh;
      const decodedToken = jwtDecode(accessToken);

      if (decodedToken.is_parent === true) {
        setAuth({ email, accessToken, refreshToken });
        await fetchProfile(accessToken);
        setPassword("");
      } else if (decodedToken.is_care_center === true) {
        setErrMsg(
          "This Email is associated with a Daycare Center account, Please click on the portal link below to access the center portal"
        );
      } else {
        setErrMsg("User does not exist."); // or some other error message
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400 && error.response.data.email) {
          setErrMsg(error.response.data.email[0]);
        } else if (error.response.status === 401) {
          if (error.response.data.error) {
            setErrMsg(error.response.data.error);
          } else {
            setErrMsg("Invalid Credentials");
          }
        } else if (error.response.status === 403) {
          sendEmail(email);
          setErrMsg(
            "Account Not Verified. Please check email for verification link"
          );
        } else {
          setErrMsg("Error Login! Please try again later");
        }
      } else {
        setErrMsg("Network Error");
      }
      errRef.current.focus();
    } finally {
      setLoading(false);
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <>
      <section className="login-holder flex flex-wrap justify-center items-center relative">
        {loading && (
          <div className="overlay-loading">
            <img src={assetImports.loadingGif} alt="Loading" />
          </div>
        )}
        <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
        <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
        <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
        <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
        <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
        <div className="container relative login-container">
          <img className="logo-image" src={assetImports.logo} alt="Logo" />
          <div className="relative login-box">
            <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
            <div className="login-form border-primary relative">
              <p
                id="loginerror"
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              <h1 className="font-lguy primary text-center">
                LOGIN TO YOUR ACCOUNT
              </h1>
              <form id="login-form" onSubmit={handleFormSubmit}>
                <label htmlFor="email"></label>
                <input
                  className="border-primary"
                  placeholder="Email Address"
                  type="email"
                  name="email"
                  id="email"
                  ref={userRef}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />

                <label htmlFor="password"></label>
                <input
                  className="border-primary"
                  placeholder="Password"
                  type="password"
                  name="password"
                  id="password"
                  onChange={handleChange}
                  required
                />

                <button className="full primary button-custom" type="submit">
                  LOG IN
                </button>
                {/* <div className="persistCheck">
                                    <input
                                        type="checkbox"
                                        id="persist"
                                        onChange={togglePersist}
                                        checked={persist}
                                    />
                                    <label htmlFor="persist">Trust This Device</label>
                                </div> */}
              </form>
              <div className="flex flex-wrap justify-between items-center">
                <Link className="primary fpassword" to="/forgot-password">
                  Forgot Password?
                </Link>
                <Link className="pink fpassword" to="/center-login">
                  Center Portal
                </Link>
                <span className="text-acc">Don't have an account?</span>
                <Link className="button-custom pink small" to="/parent-signup">
                  {" "}
                  SIGN UP{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
