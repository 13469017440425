// import axios from 'axios';
import React, { useRef, useState, useEffect } from "react";
import axiosInstance from "axios";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import assetImports from "../../AssetImport/assetImports";
import { Link } from "react-router-dom";
import styles from "./styles.css";
import {
  CENTER_SIGNUP_URL,
  RESEND_EMAIL_URL,
  SUPPORT_EMAIL,
} from "../../../apiUrls";

function CenterSignUp() {
  const { setAuth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setConfirmPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [msg, setMsg] = useState("");

  const sendEmail = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(RESEND_EMAIL_URL, { email });
      setLoading(false);
      setMsg("Another Email has been sent!");
    } catch (error) {
      if (error.response) {
        setErrMsg(error.response);
      } else {
        setErrMsg("Network Error");
      }
      setLoading(false);
      errRef.current.focus();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value.trim().toLowerCase());
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "password2") {
      setConfirmPassword(value);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (email.trim() === "" || password === "") {
      setErrMsg("Please fill out both email and password fields.");
      setLoading(false);
      return;
    }
    if (password !== password2) {
      setErrMsg("Password and confirm password do not match.");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.post(CENTER_SIGNUP_URL, {
        email,
        password,
        password2,
      });
      setAuth({ email });
      setPassword("");
      setLoading(false);
      setMsg("Please verify your email");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400 && error.response.data.errors) {
          setErrMsg(error.response.data.errors);
        } else if (error.response.status === 401) {
          setErrMsg("Invalid Credentials");
        } else {
          setErrMsg("Unable to sign up! Please try again later");
        }
      } else {
        setErrMsg("Network Error");
      }
      setLoading(false);
      errRef.current.focus();
    }
  };

  return (
    <>
      <div
        id="centerSignup"
        className="sign-up-div login-holder flex flex-wrap justify-center items-center relative"
      >
        {loading && (
          <div className="overlay-loading">
            <img src={assetImports.loadingGif} alt="Loading" />
          </div>
        )}
        <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
        <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
        <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
        <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
        <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
        <div className="container relative login-container">
          <img className="logo-image" src={assetImports.logo} alt="Logo" />
          <div className="relative login-box">
            <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
            <div className="login-form border-primary relative">
              {msg && (
                <div className="">
                  <img src={assetImports.messageIcon} className="messageIcon" />
                  <h1 className="font-lguy primary text-center">{msg}</h1>
                  <p className="text-center primary">
                    You're almost there! We sent an email to
                    <br />
                    <strong>{email}</strong>
                  </p>
                  <p className="text-center primary text-mid">
                    Just click on the link in that email to complete your
                    signup. If you don’t see it, you may need to check your spam
                    folder.
                  </p>
                  <p className="text-acc text-center primary email-acc">
                    Still can't find the email?
                  </p>
                  <button
                    className="button-custom primary full"
                    onClick={sendEmail}
                  >
                    {" "}
                    RESEND VERIFICATION EMAIL
                  </button>
                  <p className="help-text text-center primary">
                    Need help?{" "}
                    <a
                      className="primary"
                      href={`mailto:${SUPPORT_EMAIL}`}
                      target="_blank"
                    >
                      <strong>Contact Us</strong>
                    </a>
                  </p>
                </div>
              )}
              {!msg && (
                <div>
                  <p
                    id="loginerror"
                    ref={errRef}
                    className={errMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                  <h1 className="font-lguy primary text-center">
                    Create an account
                  </h1>
                  <form
                    id="center-sign-up-form"
                    className="sign-up-form"
                    onSubmit={handleFormSubmit}
                  >
                    <label htmlFor="email"></label>
                    <input
                      className="border-primary"
                      placeholder="Email Address"
                      type="email"
                      name="email"
                      id="email"
                      ref={userRef}
                      autoComplete="off"
                      onChange={handleChange}
                      required
                    />

                    <label htmlFor="password"></label>
                    <input
                      className="border-primary"
                      placeholder="Password"
                      type="password"
                      name="password"
                      id="password"
                      onChange={handleChange}
                      required
                    />

                    <label htmlFor="password2"></label>
                    <input
                      className="border-primary"
                      placeholder="Confirm Password"
                      type="password"
                      name="password2"
                      id="password2"
                      onChange={handleChange}
                      required
                    />
                    <button
                      className="button-custom primary create mx-auto"
                      type="submit"
                    >
                      CREATE ACCOUNT
                    </button>
                  </form>
                </div>
              )}
            </div>
            <div className="flex flex-wrap justify-end items-center text-box">
              <span className="text-acc">Already have an account?</span>
              <Link className="button-custom pink" to="/center-login">
                {" "}
                LOG IN{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CenterSignUp;
