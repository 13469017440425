import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axios.js";
import useAuth from "../../hooks/useAuth";
import useRefreshToken from "../../hooks/useRefreshToken";
import getCenterProfile from "../Profile/centerProfileAPI";
import assetImports from "../AssetImport/assetImports";
import { useNavigate, Link, useParams } from "react-router-dom";
import DaycareSidebar from "../Sidebar/daycareSidebar.js";
import { Tooltip } from "react-tooltip";
import {
  ACCEPT_CHILD_REGISTRATION,
  GET_REGISTRATION_IN_WAITLIST,
  GIVE_REASON_REJECTION,
  GET_WAITLISTS_BY_CENTER,
  DELETE_CHILD_FROM_WAITLIST,
} from "../../apiUrls.js";
import styles from "./styles.css";
import BannerDaycare from "../Banner/bannerDaycare.js";
import Preloader from "../../Preloader.js";

const CenterDashboard = () => {
  let { slug } = useParams();
  const [dataLoaded, setDataLoaded] = useState(false);
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const [center_name, setCenterName] = useState("");
  const [registrations, setRegistrations] = useState([]);
  const [nameFull, setNameFull] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [waitlistsIds, setWaitlistsIds] = useState([]);
  const [errMsgUpdate, setErrMsgWaitlist] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState({});
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const [selectedOption, setSelectedOption] = useState(null);
  const [customValue, setCustomValue] = useState("");

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    // Check if the viewport width is less than a certain value (e.g., 768 pixels for mobile)
    const isMobile = window.innerWidth < 768;

    // Set isOpen to true if it's a mobile viewport
    setIsSidebarOpen(!isMobile);
  }, []); // Only run this effect once when the component mounts

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); // Function to close the sidebar
  };

  const options = [
    { value: "option1", label: "Over the age in this waitlist" },
    { value: "option2", label: "Exceed the date expected Limit" },
    { value: "option3", label: "Too Young to start in this center" },
    { value: "option4", label: "Center is currently under renovation" },
    { value: "Other", label: "Other" },
  ];

  const handleCustomValueChange = (e) => {
    // Update custom value when the user types in the input field
    const customValue = e.target.value;
    setCustomValue(customValue);
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value; // Get the value of the selected option
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    ); // Find the corresponding option object
    const selectedLabel = selectedOption ? selectedOption.label : ""; // Extract the label from the option object
    setSelectedOption(selectedLabel);
    // Reset custom value when an option is selected
  };

  const openDeleteModal = (registrationId, childName) => {
    setDeleteModalData({
      registrationId,
      childName,
    });
    setIsDeleteModalOpen(true);
  };

  const openInfoModal = (
    parentName,
    parentEmail,
    parentPhone,
    childName,
    expectedStartDate
  ) => {
    setModalData({
      parentName,
      parentEmail,
      parentPhone,
      childName,
      expectedStartDate,
    });
    setIsInfoModalOpen(true);
  };

  const closeModal = () => {
    setIsDeleteModalOpen(false);
    setIsInfoModalOpen(false);
    // You can also clear modalData here if needed
  };

  useEffect(() => {
    const getWaitlists = async () => {
      try {
        const waitlists = await axiosInstance.get(GET_WAITLISTS_BY_CENTER, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        });
        const waitlistsData = waitlists.data.map((waitlist) => ({
          id: waitlist.id,
          size: waitlist.size,
        }));
        setWaitlistsIds(waitlistsData);

        const registrationsPromises = waitlists.data.map((waitlist) => {
          return axiosInstance.get(GET_REGISTRATION_IN_WAITLIST, {
            params: {
              id: waitlist.id,
            },
            headers: {
              Authorization: `Bearer ${auth.accessToken}`,
            },
          });
        });

        const registrationsResponses = await Promise.all(registrationsPromises);
        const registrationsData = registrationsResponses.map(
          (response, index) => {
            return {
              waitlist_name: response.data.data.waitlist_name,
              waitlist_registrations: response.data.data.registrations.map(
                (registration) => ({
                  ...registration,
                  size: waitlistsData[index].size,
                })
              ),
            };
          }
        );

        setRegistrations(registrationsData);
      } catch (error) {
        console.error("Error fetching waitlists:", error);
        setErrMsgWaitlist("Error fetching waitlists. Please try again later.");
      }
    };
    getWaitlists();
  }, [auth.accessToken]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getCenterProfile(auth.accessToken);
        const data = response.data;
        if (data && data.center_name) {
          setCenterName(data.center_name);
        } else {
          console.error("Invalid response format:", data);
        }
        setTimeout(() => {
          setDataLoaded(true);
        }, 1000);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Access token expired or invalid, refreshing token...");
          await refresh();
        } else {
          navigate("/create-center-profile");
          setErrMsg(`Error fetching center profile: ${error.message}`); // Handle other errors
        }
      }
    };
    fetchProfile();
  }, [auth.accessToken]);

  const handleFormDelete = async (event, regID, childName) => {
    event.preventDefault();
    setLoading(true);
    const valueToSend =
      selectedOption === "Other" ? customValue : selectedOption;
    try {
      await axiosInstance.patch(
        GIVE_REASON_REJECTION,
        { reason_for_deletion: valueToSend },
        {
          params: {
            id: regID,
          },
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      await axiosInstance.delete(DELETE_CHILD_FROM_WAITLIST, {
        params: {
          id: regID,
        },
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      setNameFull(childName);
      setTimeout(() => {
        setIsDeleteModalOpen(false);
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessDelete(true);
      }, 1000);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred";
        setErrMsgWaitlist(`Server Error: ${errorMessage}`);
      } else if (error.request) {
        setErrMsgWaitlist("Network Error: The server did not respond");
      } else {
        setErrMsgWaitlist("An unexpected error occurred");
      }
      setNameFull("");
      setTimeout(() => {
        setIsDeleteModalOpen(true);
        setLoading(false);
      }, 1000);
      setSuccessDelete(false);
    }
  };

  const acceptRegistration = async (listID, childName) => {
    setLoading(true);
    try {
      await axiosInstance.post(
        `${ACCEPT_CHILD_REGISTRATION}`,
        { registration: listID },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setNameFull(childName);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessUpdate(true);
      }, 1000);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred";
        if (error.response.status === 400) {
          setErrMsgWaitlist(error.response.data.errors);
        } else {
          setErrMsgWaitlist(`Server Error: ${errorMessage}`);
        }
      } else if (error.request) {
        setErrMsgWaitlist("Network Error: The server did not respond");
      } else {
        setErrMsgWaitlist("An unexpected error occurred");
      }
      setNameFull("");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSuccessUpdate(false);
    }
  };

  const handleUpdate = () => {
    setSuccessDelete(false);
    setSuccessUpdate(false);
    window.location.reload();
  };

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section>
      {isDeleteModalOpen && (
        <div className="overlay-loading dark">
          <div className="modal-content flex flex-wrap items-center justify-center rounded-edge">
            <div className="modal-inner bigger full">
              <img className="success" src={assetImports.deleteSvg} alt="" />
              <form
                id="delete-form"
                className="items-start bg-white"
                onSubmit={(event) =>
                  handleFormDelete(
                    event,
                    deleteModalData.registrationId,
                    deleteModalData.childName
                  )
                }
              >
                <div className="flex flex-wrap justify-between items-start full">
                  <p
                    id="loginerror"
                    className={errMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                  <h3 className="font-bold full text-left">
                    Reject and Remove {deleteModalData.childName} from this
                    waitlist!
                  </h3>
                  <p className="full text-left">
                    Please choose a suitable reason for rejecting this child
                  </p>
                  <div className="radio-col-field">
                    {options.map((option) => (
                      <label key={option.value} className="radio-container">
                        {option.label}
                        <input
                          type="radio"
                          value={option.value}
                          checked={selectedOption === option.label}
                          onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    ))}
                    {/* Render input field if custom option is selected */}
                    {selectedOption === "Other" ? (
                      <textarea
                        rows="5"
                        type="text"
                        value={customValue}
                        onChange={handleCustomValueChange}
                        placeholder="Enter Custom Message"
                      />
                    ) : null}
                  </div>
                  <button className="button-custom primary" type="submit">
                    SUBMIT
                  </button>
                </div>
              </form>
              <button className="close-overlay" onClick={closeModal}>
                <span>X</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {isInfoModalOpen && (
        <div className="overlay-loading dark">
          <div className="modal-content flex flex-wrap items-center justify-center rounded-edge">
            <div className="modal-inner full">
              <div className="view-profile-list flex flex-wrap items-center">
                <div className="profile-content">
                  <p>
                    <span className="font-semibold">
                      Child Name:{" "}
                      <span className="font-normal">{modalData.childName}</span>
                    </span>
                  </p>
                  <p>
                    <span className="font-semibold">
                      Expected Start Date:{" "}
                      <span className="font-normal">
                        {modalData.expectedStartDate}
                      </span>
                    </span>
                  </p>
                  <p>
                    <span className="font-semibold">
                      Parent:{" "}
                      <span className="font-normal">
                        {modalData.parentName}
                      </span>
                    </span>
                  </p>
                  <p>
                    <span className="font-semibold">Phone: </span>
                    <a
                      className="black font-normal"
                      target="_blank"
                      href={`tel:${modalData.parentPhone}`}
                    >
                      {modalData.parentPhone}
                    </a>
                  </p>
                  <p>
                    <span className="font-semibold">Email: </span>
                    <a
                      className="black font-normal"
                      target="_blank"
                      href={`mailto:${modalData.parentEmail}`}
                    >
                      {modalData.parentEmail}
                    </a>
                  </p>
                  <a
                    className="primary button-custom"
                    href={`mailto:${modalData.parentEmail}`}
                    target="_blank"
                  >
                    Send Message
                  </a>
                </div>
                <div>
                  <div className="border-pink bg-pink avatar-div items-center justify-center flex flex-wrap mx-auto">
                    <img src={assetImports.avatar} alt="" />
                  </div>
                </div>
              </div>
              <button className="close-overlay" onClick={closeModal}>
                <span>X</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="overlay-loading">
          <img src={assetImports.loadingGif} alt="Loading" />
        </div>
      )}
      <img id="blueBlurbProfile" src={assetImports.blueBlurb} alt="" />
      <img id="greenBlurbProfile" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurbFullProfile" src={assetImports.pinkBlurbFull} alt="" />
      <div>
        <BannerDaycare
          toggleSidebar={toggleSidebar}
          title={`${center_name}'s DASHBOARD`}
        />
      </div>
      <div className="flex flex-wrap sidebar-container relative">
        <DaycareSidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
        <div className="content-sidebar">
          <div className="inner-full">
            {successUpdate && (
              <div className="overlay-loading dark">
                <div className="modal-content flex flex-wrap items-center justify-center">
                  <div className="modal-inner bigger">
                    <img
                      className="success"
                      src={assetImports.successSvg}
                      alt="Successfull Update"
                    />
                    <h2 className="text-center logout-text font-bold">
                      {nameFull} has been accepted into your day care waitlist
                    </h2>
                    <p>
                      We are pleased to inform you that this child has been
                      successfully accepted in your daycare facility pending
                      parent confirmation. A message has been sent to the parent
                      to confirm or decline this acceptance. Accordingly, the
                      child's status will be updated from the waitlist to the
                      accepted list. We appreciate your attention to this
                      update.
                    </p>
                    <button className="close-overlay" onClick={handleUpdate}>
                      <span>X</span>
                    </button>
                    <Link
                      className="button-custom primary lowercase"
                      to="/accepted-list"
                    >
                      Go To Accepted List
                    </Link>
                    <button
                      className="button-custom primary lowercase"
                      onClick={handleUpdate}
                    >
                      <span>Go To Dashboard</span>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {successDelete && (
              <div className="overlay-loading dark">
                <div className="modal-content flex flex-wrap items-center justify-center">
                  <div className="modal-inner bigger">
                    <img
                      className="success"
                      src={assetImports.successSvg}
                      alt="Successfull Update"
                    />
                    <h2 className="red text-center logout-text font-bold">
                      {nameFull} has been deleted successfully from your day
                      care waitlist!
                    </h2>
                    <p>
                      We are pleased to inform you that this child has been
                      successfully deleted from your daycare waitlist.
                      Accordingly, the child's status will be updated from the
                      waitlist to the deleted list. We appreciate your attention
                      to this update.
                    </p>
                    <button className="close-overlay" onClick={handleUpdate}>
                      <span>X</span>
                    </button>
                    <Link
                      className="button-custom primary lowercase"
                      to="/rejected-list"
                    >
                      Go To Rejected List
                    </Link>
                    <button
                      className="button-custom primary lowercase"
                      onClick={handleUpdate}
                    >
                      <span>Go To Dashboard</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
            <p
              id="loginerror"
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            {registrations.length === 0 ? (
              <div>
                <h3 className="text-dashboard">
                  Hi, welcome to your dashboard!
                </h3>
                <div className="flex flex-wrap box-dash">
                  <Link
                    to="/waitlists"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div>
                      <div className="border-col flex flex-wrap justify-center items-center">
                        <span>+</span>
                      </div>
                      <p className="text-white">Create a Waitlist</p>
                    </div>
                  </Link>
                  <Link
                    to="/profile"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div className="profile-box">
                      <img src={assetImports.profile} alt="Update Profile" />
                      <p className="text-white">Update Profile</p>
                    </div>
                  </Link>
                  <Link
                    to="/logout"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div>
                      <div className="border-col flex flex-wrap justify-center items-center">
                        <img src={assetImports.logoutSvg} alt="Logout" />
                      </div>
                      <p className="text-white">Logout</p>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <br></br>
                <h3 className="text-dashboard">
                  Here is a quick <strong>overview</strong> of your current
                  available registrations on your <strong>waitlists</strong>
                </h3>
                <p
                  id="loginerror"
                  className={errMsgUpdate ? "errmsg full" : "offscreen full"}
                  aria-live="assertive"
                >
                  {errMsgUpdate}
                </p>
                <div className="form-div flex flex-wrap justify-start">
                  {registrations.map((waitlistInfo, index) => {
                    return (
                      <div className="div-waitlist bg-white" key={index}>
                        <div className="reg-holder">
                          <p className="nomargin">
                            <strong>
                              {waitlistInfo.waitlist_name} Waitlist
                            </strong>
                          </p>
                          {waitlistInfo.waitlist_registrations.length > 0 ? (
                            waitlistInfo.waitlist_registrations
                              .slice(0, 3)
                              .map((match, matchIndex) => (
                                <div
                                  className="registered-col flex flex-wrap items-start justify-between"
                                  key={matchIndex}
                                >
                                  <div className="child-info-div">
                                    <p className="child-info">
                                      {match.child.first_name}{" "}
                                      {match.child.last_name} -{" "}
                                      {match.child.position}/{match.size}
                                    </p>
                                    <p className="smaller-para">
                                      Expected Start Date:{" "}
                                      {match.expected_start_date}
                                    </p>
                                  </div>

                                  <div className="button-holder flex flex-wrap items-center">
                                    <button
                                      className="act-like-no-button nohover info"
                                      data-tooltip-id="info-tooltip"
                                      data-tooltip-content="Registration Info"
                                      onClick={() =>
                                        openInfoModal(
                                          match.child.parent.first_name +
                                            " " +
                                            match.child.parent.last_name,
                                          match.child.parent.email,
                                          match.child.parent.phone,
                                          match.child.first_name +
                                            " " +
                                            match.child.last_name,
                                          match.expected_start_date
                                        )
                                      }
                                    >
                                      {" "}
                                      <img
                                        className="icon-info"
                                        src={assetImports.iconSvg}
                                        alt=""
                                      />
                                    </button>
                                    <Tooltip
                                      id="info-tooltip"
                                      place="top"
                                      type="dark"
                                      effect="solid"
                                    />
                                    {match.qualified == true &&
                                      match.child.position === 1 && (
                                        <>
                                          <button
                                            className="act-like-no-button nohover accept"
                                            data-tooltip-id="accept-tooltip"
                                            data-tooltip-content="Accept Registration"
                                            onClick={() =>
                                              acceptRegistration(
                                                match.reg_id,
                                                match.child.first_name +
                                                  " " +
                                                  match.child.last_name
                                              )
                                            }
                                          >
                                            <span className="checkmark draw"></span>
                                          </button>
                                          <Tooltip
                                            id="accept-tooltip"
                                            place="top"
                                            type="dark"
                                            effect="solid"
                                          />
                                        </>
                                      )}
                                    <button
                                      className="act-like-no-button nohover delete"
                                      data-tooltip-id="delete-tooltip"
                                      data-tooltip-content="Delete Registration"
                                      onClick={() =>
                                        openDeleteModal(
                                          match.reg_id,
                                          match.child.first_name +
                                            " " +
                                            match.child.last_name
                                        )
                                      }
                                    ></button>
                                    <Tooltip
                                      id="delete-tooltip"
                                      place="top"
                                      type="dark"
                                      effect="solid"
                                    />
                                  </div>
                                </div>
                              ))
                          ) : (
                            <div className="registered-col">
                              <p className="red">
                                No Qualified Registrations Yet
                              </p>
                            </div>
                          )}
                          {waitlistInfo.waitlist_registrations.length > 3 && (
                            <Link
                              className="linkwaitlist button-custom primary"
                              to={`/registrations/${waitlistInfo.waitlist_name}`}
                            >
                              See More
                            </Link>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Link
                  className="createList button-custom primary"
                  to={`/waitlists`}
                >
                  Create Waitlist
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CenterDashboard;
