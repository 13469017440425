import { Outlet, useLocation , NavLink, Link } from "react-router-dom";
import React, { useState }  from 'react';
import assetImports from "../AssetImport/assetImports";
import { SUPPORT_EMAIL } from "../../apiUrls";
import styles from "./styles.css";

const DaycareSidebar = ({ isOpen, onClose }) => {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track if the arrow menu is open
    const isActive = (path) => location.pathname === path;

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle the state of the arrow menu
    };

    const handleSidebarClose = () => {
        onClose(); // Call the onClose function passed from the parent component
    };

    return (
        <>
        <div className={`sidebar ${isOpen ? 'menu-open-nav' : ''} ${isMenuOpen ? 'menu-open' : ''}`}>
            <button className='close-menu act-like-no-button nohover show-for-mobile' onClick={handleSidebarClose}><span>X</span></button>
            <div className="sidebar-box">
                <ul>
                    <li className="arrow-menu hide-for-mobile" onClick={toggleMenu}>
                        <img src={assetImports.arrowSidebar} alt=""/>
                    </li>
                    <li>
                        <NavLink to="/center-dashboard" className={isActive("/center-dashboard") ? "active" : ""}>
                            <img src={assetImports.dashboardSvg} alt="Logo" /> 
                            <span>Dashboard</span>
                        </NavLink> 
                    </li>
                    <li>
                        <NavLink to="/waitlists" className={isActive("/child-info") ? "active" : ""}>
                            <img src={assetImports.childInforSVG} alt="Logo" /> 
                            <span>Waitlists</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/center-profile" className={isActive("/center-profile") ? "active" : ""}>
                            <img src={assetImports.profile} alt="Logo" /> 
                            <span>Profile</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/accepted-list" className={isActive("/accepted-list") ? "active" : ""}>
                            <img src={assetImports.childInforSVG} alt="Logo" /> 
                            <span>Accepted List</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/rejected-list" className={isActive("/rejected-list") ? "active" : ""}>
                            <img src={assetImports.childInforSVG} alt="Logo" /> 
                            <span>Rejected List</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/logout">
                            <img src={assetImports.logoutSvg} alt="Logo" /> 
                            <span>Logout</span>
                        </NavLink> 
                    </li> 
                </ul>
            </div>
            <div className="supportBox">
                <ul>
                    <li className="support-icon">
                        <Link to={`mailto:${SUPPORT_EMAIL}`} target="_blank">
                            <img src={assetImports.supportSvg} alt="Support Logo" /> 
                            <span>Support</span>
                        </Link> 
                    </li> 
                </ul>
                <p className="text-white text-center copyright">Daycare Hub 2024</p>
                <p className="text-white text-center copyright-mini">DC 2024</p>
            </div>
        </div>
        <Outlet />
        </>
    )
};

export default DaycareSidebar;