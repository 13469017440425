import { useEffect, useState, useRef, Fragment } from "react";
import { useLocation, Link } from "react-router-dom";
import axiosInstance from "../../api/axios";
// import success from "../../images/success.png";
import styles from "./styles.css";
import assetImports from "../AssetImport/assetImports";
import { jwtDecode } from "jwt-decode";
import { ANONYMOUS_CHANGE_PASSWORD, RESET_PASSWORD } from "../../apiUrls";
import Preloader from "../../Preloader";

const PasswordReset = () => {
  const [validUrl, setValidUrl] = useState(true);
  const location = useLocation();
  const userRef = useRef();
  const errRef = useRef();
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [password, setPassword] = useState("");
  const [password2, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [msg, setMsg] = useState("");
  const token = searchParams.get("token");
  const decodedToken = jwtDecode(token);
  const email = decodedToken.email;
  const [dataLoaded, setDataLoaded] = useState(false);

  setTimeout(() => {
    setDataLoaded(true);
  }, 1000);
  // console.log(decodedToken.email);

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const data = await axiosInstance.get(
          `${RESET_PASSWORD}?token=${token}`
        );
        setValidUrl(true);
      } catch (error) {
        console.log(error);
        setValidUrl(false);
      }
    };
    verifyEmailUrl();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "password2") {
      setConfirmPassword(value);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (password2 === "" || password === "") {
      setLoading(false);
      setErrMsg("Please fill out both email and password fields.");
      return;
    }
    if (password !== password2) {
      setLoading(false);
      setErrMsg("Password and confirm password do not match.");
      return;
    }

    try {
      const response = await axiosInstance.post(ANONYMOUS_CHANGE_PASSWORD, {
        email,
        password,
        password2,
      });
      setPassword("");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccess(true);
      }, 1000);
      setMsg(response.data.message);
      console.log(response.data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setErrMsg("Error Changing Password, please try again");
        } else if (error.response.status === 401) {
          setErrMsg("Invalid Credentials");
        } else {
          setErrMsg("Error Changing Password, please try again");
        }
      } else {
        setErrMsg("Network Error");
      }
      setLoading(false);
      errRef.current.focus();
    }
  };

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <>
      {validUrl ? (
        <section className="login-holder flex flex-wrap justify-center items-center relative">
          {loading && (
            <div className="overlay-loading">
              <img src={assetImports.loadingGif} alt="Loading" />
            </div>
          )}
          <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
          <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
          <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
          <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
          <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
          <div className="container relative login-container">
            <img className="logo-image" src={assetImports.logo} alt="Logo" />
            <div className="relative login-box">
              <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
              <div className="login-form relative border-primary">
                {!success && (
                  <div>
                    <p
                      id="loginerror"
                      ref={errRef}
                      className={errMsg ? "errmsg" : "offscreen"}
                      aria-live="assertive"
                    >
                      {errMsg}
                    </p>
                    <h1 className="font-lguy primary text-center logout-text">
                      Reset Password
                    </h1>
                    <form id="reset-password-form" onSubmit={handleFormSubmit}>
                      <label htmlFor="password"></label>
                      <input
                        className="border-primary"
                        placeholder="Password"
                        type="password"
                        name="password"
                        id="password"
                        onChange={handleChange}
                        required
                      />

                      <label htmlFor="password2"></label>
                      <input
                        className="border-primary"
                        placeholder="Confirm Password"
                        type="password"
                        name="password2"
                        id="password2"
                        onChange={handleChange}
                        required
                      />
                      <button
                        className="button-custom full primary"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                )}
                {success && (
                  <div>
                    <h1 className="font-lguy primary text-center logout-text">
                      Password has been successfully reset
                    </h1>
                    <div className="flex flex-wrap justify-center items-center">
                      <Link className="button-custom pink" to="/">
                        {" "}
                        LOG IN{" "}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="login-holder flex flex-wrap justify-center items-center relative">
          <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
          <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
          <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
          <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
          <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
          <div className="container relative login-container">
            <img className="logo-image" src={assetImports.logo} alt="Logo" />
            <div className="relative login-box">
              <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
              <div className="login-form relative border-primary">
                {/* <img src={success} alt="success_img" className={styles.success_img} /> */}
                <h1 className="font-lguy primary text-center logout-text">
                  404 not found
                </h1>
                <div className="flex flex-wrap justify-center items-center">
                  <Link className="button-custom pink" to="/">
                    {" "}
                    LOG IN{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PasswordReset;
