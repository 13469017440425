import { useState, useEffect } from "react";
import axiosInstance from "../../../api/axios";
import assetImports from "../../AssetImport/assetImports";
import {
  ADD_CHILD,
  GET_CHILDREN_LIST_URL,
  UPDATE_CHILD,
  DELETE_CHILD,
} from "../../../apiUrls";
import ProfileBar from "../../NameProfileBar/nameprofilebar";
import useAuth from "../../../hooks/useAuth";
import getCurrentProfile from "../../Profile/profileAPI";
import useRefreshToken from "../../../hooks/useRefreshToken";
import Banner from "../../Banner/banner";
import Sidebar from "../../Sidebar/sidebar";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.css";
import Preloader from "../../../Preloader";

const ChildInfo = () => {
  const refresh = useRefreshToken();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { auth } = useAuth();
  const [maxDate, setMaxDate] = useState("");
  const [updateForms, setUpdateForms] = useState([
    { first_name: "", last_name: "", date_of_birth: "" },
  ]); // State for update forms
  const [addForms, setAddForms] = useState([
    { first_name: "", last_name: "", date_of_birth: "" },
  ]); // State for add new child forms
  const [loading, setLoading] = useState(false);
  const [profile_first_name, setProfileFirstName] = useState("");
  const [profile_full_name, setProfileFullName] = useState("");
  // const [parent, setProfileParent] = useState('');
  const [errMsg, setErrMsg] = useState("");
  const [errMsgUpdate, setErrMsgUpdate] = useState("");
  const [errMsgChildren, setErrMsgChildren] = useState("");
  const [successAdd, setSuccessAdd] = useState(false);
  const [successUpdate, setSuccessUpdate] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [minimizedUpdate, setMinimizedUpdate] = useState([]);
  const [minimizedAdd, setMinimizedAdd] = useState([false]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); // Function to close the sidebar
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear(),
      today.getMonth() + 6,
      today.getDate()
    );
    const formattedMaxDate = `${maxDate.getFullYear()}-${(
      maxDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${maxDate.getDate().toString().padStart(2, "0")}`;
    setMaxDate(formattedMaxDate);
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getCurrentProfile(auth.accessToken);
        setProfileFirstName(response.data.first_name);
        setProfileFullName(
          `${response.data.first_name} ${response.data.last_name}`
        );
        setTimeout(() => {
          setDataLoaded(true);
        }, 1000);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Access token expired or invalid, refreshing token...");
          await refresh();
        } else {
          console.log("Error fetching current profile:", error);
          setErrMsg("Error fetching current profile. Please try again later."); // Handle other errors
        }
        setErrMsg("Error fetching current profile:");
        navigate("/login");
      }
    };
    fetchProfile();
  }, [auth.accessToken]);

  useEffect(() => {
    const getChildrenList = async () => {
      try {
        const childrenList = await axiosInstance.get(GET_CHILDREN_LIST_URL, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        });
        if (childrenList.data.length > 0) {
          setAddForms([]);
        }
        setUpdateForms(childrenList.data);
        setMinimizedUpdate(childrenList.data.map(() => true));
      } catch (error) {
        console.error("Error fetching children list:", error);
        setErrMsgChildren(
          "Error fetching children list. Please try again later."
        );
      }
    };
    getChildrenList();
  }, [auth.accessToken]);

  const deleteChild = async (index, id) => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete(`${DELETE_CHILD}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessDelete(true);
        setUpdateForms((prevChildren) => {
          const updatedChildren = [...prevChildren];
          updatedChildren.splice(index, 1); // Remove the child at the specified index
          return updatedChildren;
        });
      }, 1000);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred";
        setErrMsgUpdate(`Server Error: ${errorMessage}`);
      } else if (error.request) {
        setErrMsgUpdate("Network Error: The server did not respond");
      } else {
        setErrMsgUpdate("An unexpected error occurred");
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSuccessDelete(false);
    }
  };

  function calculateAge(birthDate) {
    const today = new Date();
    const birth = new Date(birthDate);
    const isFutureDate = birth > today;

    let ageYears = today.getFullYear() - birth.getFullYear();
    let ageMonths = today.getMonth() - birth.getMonth();

    if (isFutureDate) {
      ageMonths = birth.getMonth() - today.getMonth();
    }

    // Adjust age if birth month is after current month
    if (
      ageMonths < 0 ||
      (ageMonths === 0 && today.getDate() < birth.getDate())
    ) {
      ageYears--;
      ageMonths = 12 - Math.abs(ageMonths);
    }

    return { years: ageYears, months: ageMonths, isFuture: isFutureDate };
  }

  // Function to format age for display
  function formatAge(age) {
    if (age.isFuture) {
      return `(Unborn) ${age.months} ${age.months === 1 ? "month" : "months"}`;
    } else {
      if (age.years >= 1) {
        return `${age.years} ${age.years === 1 ? "year" : "years"}`;
      } else {
        return `${age.months} ${age.months === 1 ? "month" : "months"}`;
      }
    }
  }

  const handleChangeUpdate = (index, e) => {
    const { name, value } = e.target;
    setUpdateForms((prevForms) => {
      const updatedForms = [...prevForms];
      // Ensure the form data for the given index is initialized
      if (!updatedForms[index]) {
        updatedForms[index] = {
          first_name: "",
          last_name: "",
          date_of_birth: "",
        };
      }

      // Update the form data with the new value
      updatedForms[index] = {
        ...updatedForms[index],
        [name]:
          name === "first_name" || name === "last_name"
            ? capitalizeFirstLetter(value)
            : value,
      };
      return updatedForms;
    });
  };

  const handleChangeAdd = (index, e) => {
    const { name, value } = e.target;
    setAddForms((prevForms) => {
      // Create a copy of the previous state
      const updatedForms = [...prevForms];
      // If the child form doesn't exist at the specified index, initialize it with default values
      if (!updatedForms[index]) {
        updatedForms[index] = {
          first_name: "",
          last_name: "",
          date_of_birth: "",
        };
      }
      // Update the value of the specified field in the child form at the given index
      updatedForms[index] = {
        ...updatedForms[index],
        [name]:
          name === "first_name" || name === "last_name"
            ? capitalizeFirstLetter(value)
            : value,
      };
      // Return the updated state
      return updatedForms;
    });
  };

  const handleFormSubmitUpdate = async (event, index, id) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.patch(
        UPDATE_CHILD,
        { ...updateForms[index] },
        {
          params: {
            id: id,
          },
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessAdd(false);
        setSuccessUpdate(true);
      }, 1000);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setErrMsgUpdate("Error updating child. Please try again later!");
        } else if (error.response.status === 401) {
          setErrMsgUpdate("Not Authorized");
        } else {
          setErrMsgUpdate("Error updating child. Please try again later!");
        }
      } else {
        setErrMsgUpdate("Error updating child. Please try again later!");
      }
      setLoading(false);
    }
  };

  const toggleMinimizeUpdate = (index) => {
    setMinimizedUpdate((prevMinimized) => {
      const updatedMinimized = [...prevMinimized];
      updatedMinimized[index] = !updatedMinimized[index];
      return updatedMinimized;
    });
  };

  const handleUpdate = () => {
    setSuccessDelete(false);
    setSuccessAdd(false);
    setSuccessUpdate(false);
    window.location.reload();
  };

  const handleFormSubmitAdd = async (event, index) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axiosInstance.post(
        ADD_CHILD,
        { ...addForms[index] },
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setSuccessUpdate(false);
        setSuccessAdd(true);
        setUpdateForms((prevChildren) => {
          const updatedChildren = [...prevChildren]; // Copy the existing array
          const newChild = addForms[index]; // Get the child from the addForms array at the specified index
          updatedChildren.push(newChild); // Add the new child to the end of the array
          return updatedChildren;
        });
      }, 1000);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setErrMsg("Account Already Exists!");
        } else if (error.response.status === 401) {
          setErrMsg("Not Authorized");
        } else {
          setErrMsg("Error adding child to profile");
        }
      } else {
        setErrMsg("Error adding child to profile");
      }
      console.log(error);
      setLoading(false);
    }
  };

  const toggleMinimizeAdd = (index) => {
    setMinimizedAdd((prevMinimized) => {
      const updatedMinimizedAdd = [...prevMinimized];
      updatedMinimizedAdd[index] = !updatedMinimizedAdd[index];
      return updatedMinimizedAdd;
    });
  };

  const handleAddForm = () => {
    setAddForms([
      ...addForms,
      { first_name: "", last_name: "", date_of_birth: "" },
    ]);
  };

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section className="childInfo">
      {loading && (
        <div className="overlay-loading">
          <img src={assetImports.loadingGif} alt="Loading" />
        </div>
      )}
      <img id="blueBlurbProfile" src={assetImports.blueBlurb} alt="" />
      <img id="greenBlurbProfile" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurbFullProfile" src={assetImports.pinkBlurbFull} alt="" />
      <div>
        <Banner
          toggleSidebar={toggleSidebar}
          title={`${profile_first_name}'s CHILD INFO`}
        />
      </div>
      <div className="flex flex-wrap sidebar-container relative">
        <Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
        <div className="content-sidebar">
          <div className="inner no-mobile-inner">
            <div className="bigger-image">
              <ProfileBar
                name={`Hi ${profile_first_name}`}
                text="Let's add your child to your profile."
                img={assetImports.profileAvatar}
              />
            </div>
            <div className="box-container inner-box-container">
              <div className="relative login-box">
                <br></br>
                <br></br>
                {addForms.length === 0 && (
                  <p className="nomargin">
                    Add a new <strong>child</strong> by clicking the add child
                    button below
                  </p>
                )}
                {addForms.length > 0 && (
                  <p className="nomargin">
                    Add a new <strong>child</strong> by entering the information
                    below and click the save button
                  </p>
                )}
                <br></br>
                <div className="login-form border-primary relative">
                  {successAdd && (
                    <div className="overlay-loading dark">
                      <div className="modal-content flex flex-wrap items-center justify-center">
                        <div className="modal-inner">
                          <img
                            className="success"
                            src={assetImports.successSvg}
                            alt="Successfull Update"
                          />
                          <h1 className="text-center logout-text">
                            Child has been added Successfully!
                          </h1>
                          <button
                            className="close-overlay"
                            onClick={handleUpdate}
                          >
                            <span>X</span>
                          </button>
                          <button
                            className="button-custom primary lowercase"
                            onClick={handleUpdate}
                          >
                            Close
                          </button>
                          <Link
                            className="button-custom primary lowercase"
                            to="/daycares"
                          >
                            Day Cares
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}

                  {successUpdate && (
                    <div className="overlay-loading dark">
                      <div className="modal-content flex flex-wrap items-center justify-center">
                        <div className="modal-inner">
                          <img
                            className="success"
                            src={assetImports.successSvg}
                            alt="Successfull Update"
                          />
                          <h1 className="text-center logout-text">
                            Child Info has been updated Successfully!
                          </h1>
                          <button
                            className="close-overlay"
                            onClick={handleUpdate}
                          >
                            <span>X</span>
                          </button>
                          <button
                            className="button-custom primary lowercase"
                            onClick={handleUpdate}
                          >
                            Close
                          </button>
                          <Link
                            className="button-custom primary lowercase"
                            to="/dashboard"
                          >
                            Go To Dashboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}

                  {successDelete && (
                    <div className="overlay-loading dark">
                      <div className="modal-content flex flex-wrap items-center justify-center">
                        <div className="modal-inner">
                          <img
                            className="success"
                            src={assetImports.successSvg}
                            alt="Successfull Update"
                          />
                          <h1 className="red text-center logout-text">
                            Child has been deleted Successfully!
                          </h1>
                          <button
                            className="close-overlay"
                            onClick={handleUpdate}
                          >
                            <span>X</span>
                          </button>
                          <button
                            className="button-custom primary lowercase"
                            onClick={handleUpdate}
                          >
                            Close
                          </button>
                          <Link
                            className="button-custom primary lowercase"
                            to="/dashboard"
                          >
                            Go To Dashboard
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}

                  <p
                    id="loginerror"
                    className={errMsgUpdate ? "errmsg full" : "offscreen full"}
                    aria-live="assertive"
                  >
                    {errMsgUpdate}
                  </p>
                  {updateForms.map((child, index) => (
                    <div
                      className={`form-div ${
                        minimizedUpdate[index] ? "minimized" : ""
                      }`}
                      key={index}
                    >
                      <div className="flex flex-wrap items-start">
                        <div className="box-child">
                          <div
                            className="bg-secondary w-full div-box"
                            onClick={() => toggleMinimizeUpdate(index)}
                          >
                            <div className="flex flex-wrap justify-between container-inner child-inner items-center">
                              <h4 className="text-white nomargin">
                                {child.first_name} {child.last_name} -{" "}
                                {formatAge(calculateAge(child.date_of_birth))}{" "}
                                old
                              </h4>
                              <div className="flex flex-wrap">
                                <button className="button-box">
                                  <span className="arrow"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                          {!minimizedUpdate[index] && (
                            <form
                              key={index}
                              id="update-child-form"
                              className="child-form"
                              onSubmit={(event) =>
                                handleFormSubmitUpdate(event, index, child.id)
                              }
                            >
                              <label
                                className="half lab-col"
                                htmlFor="first_name"
                              >
                                <span className="label required">
                                  First Name
                                </span>
                                <input
                                  type="text"
                                  defaultValue={child.first_name}
                                  onChange={(e) => handleChangeUpdate(index, e)}
                                  className="border-secondary"
                                  name="first_name"
                                  required
                                  placeholder="First Name"
                                />
                              </label>
                              <label
                                className="half lab-col"
                                htmlFor="last_name"
                              >
                                <span className="label required">
                                  Last Name
                                </span>
                                <input
                                  type="text"
                                  defaultValue={child.last_name}
                                  onChange={(e) => handleChangeUpdate(index, e)}
                                  className="border-secondary"
                                  name="last_name"
                                  required
                                  placeholder="Last Name"
                                />
                              </label>
                              <label
                                className="half lab-col"
                                htmlFor="date_of_birth"
                              >
                                <span className="label required">
                                  Date of Birth
                                </span>
                                <input
                                  type="date"
                                  defaultValue={child.date_of_birth}
                                  onChange={(e) => handleChangeUpdate(index, e)}
                                  className="border-secondary"
                                  name="date_of_birth"
                                  required
                                  placeholder="Date of Birth"
                                  max={maxDate}
                                />
                              </label>
                              <label
                                className="half lab-col"
                                htmlFor="parent-name"
                              >
                                <span className="label required">
                                  Parent Name
                                </span>
                                <input
                                  type="text"
                                  value={profile_full_name}
                                  className="border-secondary"
                                  readOnly
                                  name="parent-name"
                                  id="parent-name"
                                  required
                                  placeholder="Parent Name"
                                />
                              </label>
                              <button
                                className="button-custom secondary"
                                type="submit"
                              >
                                UPDATE
                              </button>
                            </form>
                          )}
                        </div>

                        <button
                          className="button-box delete"
                          onClick={() => deleteChild(index, child.id)}
                        >
                          <span className="text-white">X</span>
                        </button>
                      </div>
                    </div>
                  ))}

                  <p
                    id="loginerror"
                    className={errMsg ? "errmsg full" : "offscreen full"}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                  {addForms.length > 0 &&
                    addForms.length <= 1 &&
                    addForms.map((child, index) => (
                      <div
                        className={`form-div ${
                          minimizedAdd[index] ? "minimized" : ""
                        }`}
                        key={index}
                      >
                        <div className="flex flex-wrap items-start">
                          <div className="box-child">
                            <div
                              className="bg-secondary w-full div-box"
                              onClick={() => toggleMinimizeAdd(index)}
                            >
                              <div className="flex flex-wrap justify-between container-inner child-inner items-center">
                                <h4 className="text-white nomargin">
                                  Fullname - Age
                                </h4>
                                <button className="button-box">
                                  <span className="arrow"></span>
                                </button>
                              </div>
                            </div>
                            {!minimizedAdd[index] && (
                              <form
                                key={index}
                                id="add-child-form"
                                className="child-form"
                                onSubmit={(event) =>
                                  handleFormSubmitAdd(event, index)
                                }
                              >
                                <label
                                  className="half lab-col"
                                  htmlFor="first_name"
                                >
                                  <span className="label required">
                                    First Name
                                  </span>
                                  <input
                                    type="text"
                                    value={child.first_name}
                                    onChange={(e) => handleChangeAdd(index, e)}
                                    className="border-secondary"
                                    name="first_name"
                                    required
                                    placeholder="First Name"
                                  />
                                </label>
                                <label
                                  className="half lab-col"
                                  htmlFor="first_name"
                                >
                                  <span className="label required">
                                    First Name
                                  </span>
                                  <input
                                    type="text"
                                    value={child.last_name}
                                    onChange={(e) => handleChangeAdd(index, e)}
                                    className="border-secondary"
                                    name="last_name"
                                    required
                                    placeholder="Last Name"
                                  />
                                </label>
                                <label
                                  className="half lab-col"
                                  htmlFor="first_name"
                                >
                                  <span className="label required">
                                    Date of Birth
                                  </span>
                                  <input
                                    type="date"
                                    value={child.date_of_birth}
                                    onChange={(e) => handleChangeAdd(index, e)}
                                    className="border-secondary"
                                    name="date_of_birth"
                                    required
                                    placeholder="Date of Birth"
                                    max={maxDate}
                                  />
                                </label>
                                <label
                                  className="half lab-col"
                                  htmlFor="first_name"
                                >
                                  <span className="label required">
                                    Parent Name
                                  </span>
                                  <input
                                    type="text"
                                    value={profile_full_name}
                                    className="border-secondary"
                                    readOnly
                                    name="parent-name"
                                    id="parent-name"
                                    required
                                    placeholder="Parent Name"
                                  />
                                </label>
                                <button
                                  className="button-custom secondary"
                                  type="submit"
                                >
                                  SAVE
                                </button>
                              </form>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  {addForms.length === 0 && (
                    <div className="flex flex-wrap justify-end">
                      <button
                        type="button"
                        className="button-custom pink addForm"
                        onClick={handleAddForm}
                      >
                        Add Child +
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChildInfo;
