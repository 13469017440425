import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import Home from "./components/Home/Home";
import Parents from "./components/Parents/parents";
import ParentSignUp from "./components/Parents/Signup/parentSignUp";
import Login from "./components/Login/login";
import Logout from "./components/Logout/logout";
import CreateParentProfile from "./components/Parents/createParentProfile";
import Dashboard from "./components/Dashboard/dashboard";
import PersistLogin from "./components/PersistLogin";
import PrivateRoutes from "./components/PrivateRoute";
import EmailVerify from "./components/EmailVerify/EmailVerify";
import ForgotPassword from "./components/Password/ForgotPassword";
import PasswordReset from "./components/Password/PasswordReset";
import Profile from "./components/Profile/Profile";
import CenterProfile from "./components/Profile/centerProfile";
import ChildInfo from "./components/Parents/Child-Info/childInfo";
import DayCareSearch from "./components/Parents/Daycare-Search/daycareSearch";
import CreateWailist from "./components/Centers/Wailists/CreateWaitlist";

//Center Components
import CenterSignUp from "./components/Centers/Signup/centersSignUp";
import CenterLogin from "./components/Login/centerLogin";
import CreateCenterProfile from "./components/Centers/createCenterProfile";
import CenterDashboard from "./components/Dashboard/centerDashboard";
import AcceptedList from "./components/Centers/Wailists/AcceptedList";
import RejectedList from "./components/Centers/Wailists/RejectedList";
import NotFound from "./components/404";
import Terms from "./components/Terms/terms";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/center-login" element={<CenterLogin />} />
          <Route path="/parents" element={<Parents />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/parent-signup" element={<ParentSignUp />} />
          <Route path="/center-signup" element={<CenterSignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/email-verify" element={<EmailVerify />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route element={<PrivateRoutes />}>
            <Route element={<PersistLogin />}>
              <Route
                exact
                path="/create-parent-profile"
                element={<CreateParentProfile />}
              />
              <Route
                exact
                path="/create-center-profile"
                element={<CreateCenterProfile />}
              />
              <Route exact path="/child-info" element={<ChildInfo />} />
              <Route exact path="/daycares" element={<DayCareSearch />} />
              <Route exact path="/accepted-list" element={<AcceptedList />} />
              <Route
                exact
                path="/accepted-list/:slug"
                element={<AcceptedList />}
              />
              <Route exact path="/rejected-list" element={<RejectedList />} />
              <Route
                exact
                path="/rejected-list/:slug"
                element={<RejectedList />}
              />
              <Route path="/daycares/:slug" element={<DayCareSearch />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route
                exact
                path="/center-dashboard"
                element={<CenterDashboard />}
              />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/center-profile" element={<CenterProfile />} />
              <Route exact path="/waitlists" element={<CreateWailist />} />
              <Route path="/registrations/:slug" element={<CreateWailist />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default function AppWithAuthProvider() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}
