import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import useRefreshToken from "../../hooks/useRefreshToken";
import getCurrentProfile from "../Profile/profileAPI";
import assetImports from "../AssetImport/assetImports";
import { useNavigate, Link } from "react-router-dom";
import axiosInstance from "../../api/axios";
import Banner from "../Banner/banner";
import Sidebar from "../Sidebar/sidebar";
import {
  GET_REGISTERATION_CHILD_ON_WAITLIST,
  PARENT_DELETE_CHILD_FROM_WAITLIST,
  CONFIRM_CHILD_ACCEPTANCE,
  DECLINE_CHILD_ACCEPTANCE,
} from "../../apiUrls";
import Preloader from "../../Preloader";
import "jspdf-autotable";

import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-alpine.css";

const Dashboard = () => {
  const refresh = useRefreshToken();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { auth } = useAuth();
  const [first_name, setFirstName] = useState("");
  const [childFullName, setChildFullName] = useState("");
  const [dayCareName, setDayCareName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [registerations, setRegisterations] = useState([]);
  const [acceptedRegisterations, setAcceptedRegisterations] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [successAccept, setSuccessAccept] = useState(false);
  const [successDecline, setSuccessDecline] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [IsModalAcceptOpen, setIsModalAcceptOpen] = useState(false);
  const [isModalDeclineOpen, setIsModalDeclineOpen] = useState(false);
  const [isModalRemoveOpen, setIsModalRemoveOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const closeModal = () => {
    setIsModalAcceptOpen(false);
    setIsModalDeclineOpen(false);
    setIsModalRemoveOpen(false);
    // You can also clear modalData here if needed
  };

  const pagination = true;
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [10, 20, 30, 40, 50];

  const [colDefs, setColDefs] = useState([
    {
      headerName: "P",
      field: "position",
      exportTitle: "Position",
      filter: false,
      floatingFilter: false,
      width: 50,
    },
    {
      headerName: "Name",
      field: "child",
      filter: true,
      floatingFilter: true,
      width: 175,
    },
    {
      headerName: "Center Name",
      field: "center",
      filter: true,
      floatingFilter: true,
      width: 260,
    },
    {
      headerName: "Type",
      field: "waitlist",
      exportTitle: "Waitlist Type",
      filter: true,
      floatingFilter: true,
      resizable: true,
      width: 120,
    },
    {
      headerName: "Date Registered",
      field: "date_registered",
      cellDataType: "date",
      valueGetter: function (params) {
        // Convert the date string to a Date object
        return new Date(params.data.data_registered);
      },
      cellRenderer: (params) => {
        // Format date for display
        return formatDate(params.value);
      },
      valueParser: function (params) {
        // Parse the date string into a Date object
        return new Date(params.newValue);
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          // Parse the date string into a Date object
          const dateCellValue = new Date(cellValue);
          // Extract date parts (year, month, day) from the cell value
          const yearCellValue = dateCellValue.getFullYear();
          const monthCellValue = dateCellValue.getMonth();
          const dayCellValue = dateCellValue.getDate();

          // Extract date parts (year, month, day) from the filter value
          const yearFilter = filterLocalDateAtMidnight.getFullYear();
          const monthFilter = filterLocalDateAtMidnight.getMonth();
          const dayFilter = filterLocalDateAtMidnight.getDate();

          // Compare the date parts (year, month, day) to determine if they match
          if (
            yearCellValue === yearFilter &&
            monthCellValue === monthFilter &&
            dayCellValue === dayFilter
          ) {
            return 0;
          } else if (
            yearCellValue < yearFilter ||
            (yearCellValue === yearFilter && monthCellValue < monthFilter) ||
            (yearCellValue === yearFilter &&
              monthCellValue === monthFilter &&
              dayCellValue < dayFilter)
          ) {
            return -1; // cellValue is less than filterValue
          } else {
            return 1; // cellValue is greater than filterValue
          }
        },
      },
      floatingFilter: "true",
      width: 195,
    },
    {
      headerName: "Expected Start Date",
      field: "expected_start_date",
      cellDataType: "date",
      valueGetter: function (params) {
        // Convert the date string to a Date object
        return new Date(params.data.expected_start_date);
      },
      cellRenderer: (params) => {
        // Format date for display
        return formatDate(new Date(params.value));
      },
      valueParser: function (params) {
        // Parse the date string into a Date object
        return new Date(params.newValue);
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          // Parse the date string into a Date object
          const dateCellValue = new Date(cellValue);
          // Extract date parts (year, month, day) from the cell value
          const yearCellValue = dateCellValue.getFullYear();
          const monthCellValue = dateCellValue.getMonth();
          const dayCellValue = dateCellValue.getDate();

          // Extract date parts (year, month, day) from the filter value
          const yearFilter = filterLocalDateAtMidnight.getFullYear();
          const monthFilter = filterLocalDateAtMidnight.getMonth();
          const dayFilter = filterLocalDateAtMidnight.getDate();

          // Compare the date parts (year, month, day) to determine if they match
          if (
            yearCellValue === yearFilter &&
            monthCellValue === monthFilter &&
            dayCellValue === dayFilter
          ) {
            return 0;
          } else if (
            yearCellValue < yearFilter ||
            (yearCellValue === yearFilter && monthCellValue < monthFilter) ||
            (yearCellValue === yearFilter &&
              monthCellValue === monthFilter &&
              dayCellValue < dayFilter)
          ) {
            return -1; // cellValue is less than filterValue
          } else {
            return 1; // cellValue is greater than filterValue
          }
        },
      },
      floatingFilter: "true",
      width: 195,
    },
    {
      headerName: "Status",
      field: "status",
      width: 105,
      sortable: false,
      cellRenderer: (params) => {
        if (params.data.accepted === "True") {
          return "Accepted";
        } else {
          return "Pending";
        }
      },
    },
    {
      headerName: "Action",
      sortable: false,
      cellRenderer: (params) => {
        // if (params.data.accepted === "True") {
        //   return null;
        // }
        if (params.data.acceptance_id) {
          return (
            <div className="flex flex-wrap h-full justify-end items-center">
              <button
                className="act-like-no-button nohover trash"
                data-tooltip-id={`info-tooltip-${params.data.reg_id}`}
                data-tooltip-content="Info"
                onClick={() => {
                  setModalData(params.data);
                  setIsModalRemoveOpen(true);
                }}
              >
                {" "}
                <img className="icon-info" src={assetImports.trashSvg} alt="" />
              </button>
              <button
                className="act-like-no-button nohover accept"
                data-tooltip-id={`accept-tooltip-${params.data.registration_id}`}
                data-tooltip-content="Accept Registration"
                onClick={() => {
                  setModalData(params.data);
                  setIsModalAcceptOpen(true);
                }}
              >
                <span className="checkmark draw"></span>
              </button>
              <button
                className="act-like-no-button nohover delete"
                data-tooltip-id={`delete-tooltip-${params.data.registration_id}`}
                data-tooltip-content="Delete Registration"
                onClick={() => {
                  setModalData(params.data);
                  setIsModalDeclineOpen(true);
                }}
              ></button>
            </div>
          );
        }
        return (
          <div className="flex flex-wrap h-full justify-end items-center">
            <button
              className="act-like-no-button nohover trash"
              data-tooltip-id={`info-tooltip-${params.data.reg_id}`}
              data-tooltip-content="Info"
              onClick={() => {
                setModalData(params.data);
                setIsModalRemoveOpen(true);
              }}
            >
              {" "}
              <img className="icon-info" src={assetImports.trashSvg} alt="" />
            </button>
          </div>
        );
      },
      width: 120,
    },
  ]);

  const getRowStyle = (params) => {
    // Check if the row data is from array1
    const isAcceptedArray = acceptedRegisterations.some(
      (item) => item === params.data
    );

    // Define the row color based on whether the row data is from array1
    return isAcceptedArray ? { background: "#cfffcf" } : null;
  };

  const mergedData = [...acceptedRegisterations, ...registerations];

  const gridOptions = {
    // Enable the column tool panel
    onGridReady: (params) => {
      setGridApi(params.api);
      setGridColumnApi(params.columnApi);
    },
    exportParams: {
      // Process cell callback for CSV export
      processCellCallback: (params) => {
        const lastColumn = params.columnApi.getAllColumns().slice(-1)[0];
        // Check if the column is the last column ('Action' column)
        if (params.column === lastColumn) {
          return null; // Return null to hide the cell
        }
        return params.value; // Return the original cell value for other columns
      },
    },
    columnDefs: colDefs,
    getRowStyle: getRowStyle,
  };

  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Function to get ordinal suffix for day
    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return `${months[monthIndex]} ${day}${getOrdinalSuffix(day)}, ${year}`;
  }

  const rowClassRules = {
    // apply red to Ford cars
    // "rag-red": (params) => params.data.qualified === false,
    "cursor-pointer": () => true,
  };

  useEffect(() => {
    // Check if the viewport width is less than a certain value (e.g., 768 pixels for mobile)
    const isMobile = window.innerWidth < 768;

    // Set isOpen to true if it's a mobile viewport
    setIsSidebarOpen(!isMobile);
  }, []); // Only run this effect once when the component mounts

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); // Function to close the sidebar
  };

  useEffect(() => {
    axiosInstance
      .get(GET_REGISTERATION_CHILD_ON_WAITLIST, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      })
      .then((response) => {
        setRegisterations(response.data.data.waitlisted);
        setAcceptedRegisterations(response.data.data.accepted);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setErrMsg("Not Authorized");
        } else {
          console.log(error);
        }
      });
  }, [auth.accessToken]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getCurrentProfile(auth.accessToken);
        const data = response.data;
        if (data && data.first_name) {
          setFirstName(data.first_name);
        } else {
          console.error("Invalid response format:", data);
        }
        setTimeout(() => {
          setDataLoaded(true);
        }, 1000);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log("Access token expired or invalid, refreshing token...");
          await refresh();
        } else {
          navigate("/create-parent-profile");
          setErrMsg(`Error fetching current profile: ${error.message}`); // Handle other errors
        }
      }
    };
    fetchProfile();
  }, [auth.accessToken, navigate, refresh]);

  const handleUpdate = () => {
    setSuccessDelete(false);
    window.location.reload();
  };

  const removeChildFromWaitlist = async (
    registrationID,
    waitlistID,
    childName,
    centerName
  ) => {
    setLoading(true);
    try {
      await axiosInstance.delete(PARENT_DELETE_CHILD_FROM_WAITLIST, {
        params: {
          registration_id: registrationID,
          waitlist_id: waitlistID,
        },
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      setChildFullName(childName);
      setDayCareName(centerName);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setIsModalDeclineOpen(false);
        setIsModalRemoveOpen(false);
        setIsModalAcceptOpen(false);
        setSuccessDelete(true);
        // setRegisterations((prevRegistrations) => {
        //   const updatedRegistrations = [...prevRegistrations];
        //   updatedRegistrations.splice(index, 1); // Remove the child at the specified index
        //   return updatedRegistrations;
        // });
      }, 1000);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred";
        setErrMsg(`Server Error: ${errorMessage}`);
      } else if (error.request) {
        setErrMsg("Network Error: The server did not respond");
      } else {
        setErrMsg("An unexpected error occurred");
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSuccessDelete(false);
    }
  };

  const confirmAcceptanceFromWaitlist = async (
    acceptanceId,
    childName,
    centerName
  ) => {
    setLoading(true);
    try {
      await axiosInstance.patch(
        `${CONFIRM_CHILD_ACCEPTANCE}?acceptance_id=${acceptanceId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setChildFullName(childName);
      setDayCareName(centerName);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setIsModalDeclineOpen(false);
        setIsModalRemoveOpen(false);
        setIsModalAcceptOpen(false);
        setSuccessAccept(true);
      }, 1000);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred";
        setErrMsg(`Server Error: ${errorMessage}`);
      } else if (error.request) {
        setErrMsg("Network Error: The server did not respond");
      } else {
        setErrMsg("An unexpected error occurred");
      }
      console.log(error);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSuccessAccept(false);
    }
  };

  const declineAcceptanceFromWaitlist = async (
    acceptanceId,
    childName,
    centerName
  ) => {
    setLoading(true);
    try {
      await axiosInstance.patch(
        `${DECLINE_CHILD_ACCEPTANCE}?acceptance_id=${acceptanceId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      setChildFullName(childName);
      setDayCareName(centerName);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setIsModalDeclineOpen(false);
        setIsModalRemoveOpen(false);
        setIsModalAcceptOpen(false);
        setSuccessDecline(true);
      }, 1000);
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred";
        setErrMsg(`Server Error: ${errorMessage}`);
      } else if (error.request) {
        setErrMsg("Network Error: The server did not respond");
      } else {
        setErrMsg("An unexpected error occurred");
      }
      console.log(error);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSuccessDecline(false);
    }
  };

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section>
      {isModalDeclineOpen && (
        <div className="overlay-loading dark">
          <div className="modal-content flex flex-wrap items-center justify-center rounded-edge">
            <div className="modal-inner full">
              <div className="flex flex-wrap items-center justify-center">
                <img
                  className=""
                  src={assetImports.exclamationMark}
                  alt="Successfull Update"
                />
                <div>
                  <h1 className="text-center logout-text">Are you sure?</h1>
                  <p>
                    Please note that declining an acceptance is irreversible,
                    and your child will be automatically removed from the
                    waitlist. By selecting the button below, you acknowledge
                    your acceptance of our{" "}
                    <a
                      className="font-bold"
                      href="/terms-conditions"
                      target="_blank"
                      aria-label="Link to Terms & Conditions"
                    >
                      terms and conditions.
                    </a>
                  </p>
                  <button
                    className="button-custom red"
                    onClick={() =>
                      declineAcceptanceFromWaitlist(
                        modalData.acceptance_id,
                        modalData.child,
                        modalData.center + " " + modalData.waitlist
                      )
                    }
                  >
                    <span>Decline Acceptance</span>
                  </button>
                  <button
                    className="button-custom secondary"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <button className="close-overlay" onClick={closeModal}>
                <span>X</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {isModalRemoveOpen && (
        <div className="overlay-loading dark">
          <div className="modal-content flex flex-wrap items-center justify-center rounded-edge">
            <div className="modal-inner full">
              <div className="flex flex-wrap items-center justify-center">
                <img
                  className=""
                  src={assetImports.exclamationMark}
                  alt="Successfull Update"
                />
                <div>
                  <h1 className="text-center logout-text">Are you sure?</h1>
                  <p>
                    Removing a child from a wailist is irreversible as your
                    child is automatically taken off the waitlist instantly! By
                    clicking on the button below, you acknowledge your
                    acceptance of our{" "}
                    <a
                      className="font-bold"
                      href="/terms-conditions"
                      target="_blank"
                      aria-label="Link to Terms & Conditions"
                    >
                      terms and conditions.
                    </a>
                  </p>
                  <button
                    className="button-custom red"
                    onClick={() =>
                      removeChildFromWaitlist(
                        modalData.registration_id,
                        modalData.waitlist_id,
                        modalData.child,
                        modalData.center + " " + modalData.waitlist
                      )
                    }
                  >
                    <span>Remove Child</span>
                  </button>
                  <button
                    className="button-custom secondary"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <button className="close-overlay" onClick={closeModal}>
                <span>X</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {IsModalAcceptOpen && (
        <div className="overlay-loading dark">
          <div className="modal-content flex flex-wrap items-center justify-center rounded-edge">
            <div className="modal-inner full">
              <div className="flex flex-wrap items-center justify-center">
                <img
                  className=""
                  src={assetImports.exclamationMark}
                  alt="Successfull Update"
                />
                <div>
                  <h1 className="text-center logout-text">Are you sure?</h1>
                  <p>
                    Accepting a child from a wailist is irreversible. By
                    selecting the button below, you acknowledge your acceptance
                    of our{" "}
                    <a
                      className="font-bold"
                      href="/terms-conditions"
                      target="_blank"
                      aria-label="Link to Terms & Conditions"
                    >
                      terms and conditions.
                    </a>
                  </p>
                  <button
                    className="button-custom green"
                    onClick={() =>
                      confirmAcceptanceFromWaitlist(
                        modalData.acceptance_id,
                        modalData.child,
                        modalData.center + " " + modalData.waitlist
                      )
                    }
                  >
                    <span>Confirm Acceptance</span>
                  </button>
                  <button
                    className="button-custom secondary"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <button className="close-overlay" onClick={closeModal}>
                <span>X</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {loading && (
        <div className="overlay-loading">
          <img src={assetImports.loadingGif} alt="Loading" />
        </div>
      )}
      <img id="blueBlurbProfile" src={assetImports.blueBlurb} alt="" />
      <img id="greenBlurbProfile" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurbFullProfile" src={assetImports.pinkBlurbFull} alt="" />
      <div>
        <Banner
          toggleSidebar={toggleSidebar}
          title={`${first_name}'s DASHBOARD`}
        />
      </div>
      <div className="flex flex-wrap sidebar-container relative">
        <Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
        <div className="content-sidebar">
          <div className="inner max-full">
            {successDelete && (
              <div className="overlay-loading dark">
                <div className="modal-content flex flex-wrap items-center justify-center">
                  <div className="modal-inner bigger">
                    <img
                      className="success"
                      src={assetImports.successSvg}
                      alt="Successfull Update"
                    />
                    <h2 className="red text-center logout-text font-bold">
                      {childFullName} has been deleted successfully from the{" "}
                      {dayCareName} Class!
                    </h2>
                    <p>
                      We are delighted to inform you that your child has been
                      successfully removed from the daycare's waitlist.
                      Consequently, your child's profile will no longer appear
                      on the dashboard. This update underscores our dedication
                      to maintaining a smooth, efficient, and transparent
                      process for all our families.
                    </p>
                    <button className="close-overlay" onClick={handleUpdate}>
                      <span>X</span>
                    </button>
                    <Link
                      className="button-custom primary lowercase"
                      to="/daycares"
                    >
                      Go To Daycares
                    </Link>
                    <button
                      className="button-custom primary lowercase"
                      onClick={handleUpdate}
                    >
                      <span>Go To Dashboard</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {successAccept && (
              <div className="overlay-loading dark">
                <div className="modal-content flex flex-wrap items-center justify-center">
                  <div className="modal-inner bigger">
                    <img
                      className="success"
                      src={assetImports.successSvg}
                      alt="Successfull Update"
                    />
                    <h2 className="text-center logout-text font-bold">
                      {childFullName} has been accepted into the {dayCareName}{" "}
                      Class!
                    </h2>
                    <p>
                      We are delighted to inform you that your acceptance to
                      begin at the daycare has been successfully confirmed. As a
                      result, your child's will be removed from the dashboard.
                      This update reflects our commitment to ensuring a smooth
                      and efficient onboarding process for all our families.
                    </p>
                    <button className="close-overlay" onClick={handleUpdate}>
                      <span>X</span>
                    </button>
                    <Link
                      className="button-custom primary lowercase"
                      to="/daycares"
                    >
                      Go To Daycares
                    </Link>
                    <button
                      className="button-custom primary lowercase"
                      onClick={handleUpdate}
                    >
                      <span>Go To Dashboard</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {successDecline && (
              <div className="overlay-loading dark">
                <div className="modal-content flex flex-wrap items-center justify-center">
                  <div className="modal-inner bigger">
                    <img
                      className="success"
                      src={assetImports.successSvg}
                      alt="Successfull Update"
                    />
                    <h2 className="text-center logout-text font-bold">
                      {childFullName} has declined the acceptance into{" "}
                      {dayCareName} Class!
                    </h2>
                    <p>
                      We acknowledge your decision to decline the acceptance to
                      begin at the daycare. As a result, your child's will be
                      removed from the dashboard. This update reflects our
                      commitment to ensuring a smooth, efficient and transparent
                      process for all our families.
                    </p>
                    <button className="close-overlay" onClick={handleUpdate}>
                      <span>X</span>
                    </button>
                    <Link
                      className="button-custom primary lowercase"
                      to="/daycares"
                    >
                      Go To Daycares
                    </Link>
                    <button
                      className="button-custom primary lowercase"
                      onClick={handleUpdate}
                    >
                      <span>Go To Dashboard</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
            <p
              id="loginerror"
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            {mergedData.length === 0 ? (
              <div>
                <h3 className="text-dashboard">
                  Hi, welcome to your dashboard!
                </h3>
                <div className="flex flex-wrap box-dash">
                  <Link
                    to="/child-info"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div>
                      <div className="border-col flex flex-wrap justify-center items-center">
                        <span>+</span>
                      </div>
                      <p className="text-white">Add a Child</p>
                    </div>
                  </Link>
                  <Link
                    to="/daycares"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div>
                      <div className="border-col flex flex-wrap justify-center items-center">
                        <span className="search">&#x1F50E; </span>
                      </div>
                      <p className="text-white">Search for a Day Care</p>
                    </div>
                  </Link>
                  <Link
                    to="/profile"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div className="profile-box">
                      <img src={assetImports.profile} alt="Update Profile" />
                      <p className="text-white">Update Profile</p>
                    </div>
                  </Link>
                  <Link
                    to="/logout"
                    className="box-add bg-secondary flex flex-wrap justify-center items-center"
                  >
                    <div>
                      <div className="border-col flex flex-wrap justify-center items-center">
                        <img src={assetImports.logoutSvg} alt="Logout" />
                      </div>
                      <p className="text-white">Logout</p>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <h3 className="text-dashboard">
                  Hi, here are the details regarding the position of your
                  children on the waitlist!
                </h3>
                <div
                  className="ag-theme-alpine col-cc-ag" // applying the grid theme
                  style={{ height: 700 }} // the grid will fill the size of the parent container
                >
                  <AgGridReact
                    gridOptions={gridOptions}
                    rowData={mergedData}
                    columnDefs={colDefs}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    rowClassRules={rowClassRules}
                    // onRowClicked={handleRowClick}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
