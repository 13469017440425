// import axios from 'axios';
import React, { useRef, useState, useEffect } from "react";
import axiosInstance from "axios";
import assetImports from "../AssetImport/assetImports";
import { Link } from "react-router-dom";
import styles from "./styles.css";
import { SEND_PASSWORD_RESET_EMAIL_URL, SUPPORT_EMAIL } from "../../apiUrls";

function ForgotPassword() {
  const userRef = useRef();
  const errRef = useRef();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [msg, setMsg] = useState("");

  const sendEmail = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(SEND_PASSWORD_RESET_EMAIL_URL, {
        email,
      });
      setLoading(false);
      setMsg("Another reset email has been sent");
    } catch (error) {
      if (error.response) {
        setErrMsg(error.response);
      } else {
        setErrMsg("Network Error");
      }
      setLoading(false);
      errRef.current.focus();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value.trim().toLowerCase());
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (email.trim() === "") {
      setErrMsg("Please fill out the email field.");
      return;
    }

    try {
      const response = await axiosInstance.post(SEND_PASSWORD_RESET_EMAIL_URL, {
        email,
      });
      setMsg("Password reset email sent");
      setLoading(false);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setErrMsg("Invalid Username");
        } else if (error.response.status === 401) {
          setErrMsg("Invalid Username");
        } else {
          setErrMsg("Invalid Username");
        }
      } else {
        setErrMsg("Network Error");
      }
      errRef.current.focus();
      setLoading(false);
    }
  };

  return (
    <>
      <div
        id="forgotPassword"
        className="sign-up-div login-holder flex flex-wrap justify-center items-center relative"
      >
        <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
        <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
        <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
        <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
        <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
        <div className="container relative login-container">
          <img className="logo-image" src={assetImports.logo} alt="Logo" />
          <div className="relative login-box">
            <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
            <div className="login-form border-primary relative">
              <p
                id="loginerror"
                ref={errRef}
                className={errMsg ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {errMsg}
              </p>
              {loading && (
                <div className="overlay-loading">
                  <img src={assetImports.loadingGif} alt="Loading" />
                </div>
              )}
              {msg && (
                <div className="">
                  <img src={assetImports.messageIcon} className="messageIcon" />
                  <h1 className="font-lguy primary text-center">{msg}</h1>
                  <p className="text-center primary">
                    We sent an email to
                    <br />
                    <strong>{email}</strong>
                  </p>
                  <p className="text-center primary text-mid">
                    Just click on the link in that email to continue the
                    process. If you don't see it, you may need to check your
                    spam folder.
                  </p>
                  <p className="text-acc text-center primary email-acc">
                    Still can't find the email?
                  </p>
                  <button
                    className="button-custom primary full"
                    onClick={sendEmail}
                  >
                    {" "}
                    RESEND RESET EMAIL
                  </button>
                  <p className="help-text text-center primary">
                    Need help?{" "}
                    <a
                      className="primary"
                      href={`mailto:${SUPPORT_EMAIL}`}
                      target="_blank"
                    >
                      <strong>Contact Us</strong>
                    </a>
                  </p>
                </div>
              )}
              {!msg && (
                <div>
                  <h1 className="font-lguy primary text-center">
                    RESET PASSWORD
                  </h1>
                  <form id="forgot-password" onSubmit={handleFormSubmit}>
                    <label htmlFor="email"></label>
                    <input
                      className="border-primary"
                      placeholder="Email Address"
                      type="email"
                      name="email"
                      id="email"
                      ref={userRef}
                      autoComplete="off"
                      onChange={handleChange}
                      required
                    />
                    <button
                      className="button-custom primary create mx-auto"
                      type="submit"
                    >
                      RESET
                    </button>
                  </form>
                </div>
              )}
            </div>
            <div className="flex flex-wrap justify-end items-center text-box">
              <span className="text-acc">Remember your login?</span>
              <Link className="button-custom pink" to="/">
                {" "}
                LOG IN{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ForgotPassword;
