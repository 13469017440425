import React from 'react';
import assetImports from './components/AssetImport/assetImports';

const Preloader = () => {
  return (
    <div className="preloader bg-primary">
      <img className="preloader-image" src={assetImports.logoWhite} alt="Loading..."/>
      <div className="spinner"></div>
    </div>
  );
}

export default Preloader;
