import { useState, Fragment } from "react";
// import success from "../../images/success.png";
import styles from "./styles.css";
import assetImports from "../AssetImport/assetImports";
import Preloader from "../../Preloader";

const Terms = () => {
  const [dataLoaded, setDataLoaded] = useState(false);

  setTimeout(() => {
    setDataLoaded(true);
  }, 1000);

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <Fragment>
      <>
        <section className="relative">
          <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
          <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
          <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
          <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
          <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
          <div className="container relative login-container">
            <div className="text-center full">
              <img
                className="logo-image text-center"
                src={assetImports.logo}
                alt="Logo"
              />
            </div>
            <div className="relative login-box">
              <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
              <div className="login-form relative border-primary">
                <h1 className="font-semibold">Terms and Conditions</h1>

                <h2 className="font-medium">1. Purpose of the Waitlist</h2>
                <p>
                  The waitlist managed by DaycareHub is designed to assist in
                  the enrollment process for our childcare programs.
                </p>

                <h2 className="font-medium">2. Application Process</h2>
                <p>
                  To apply for placement on the waitlist, parents/guardians must
                  complete and submit the online application form available on
                  the [Daycare Name] website. A non-refundable application fee
                  of [Fee Amount] is required upon submission.
                </p>

                <h2 className="font-medium">3. Priority Criteria</h2>
                <p>
                  Priority for enrollment from the waitlist will be given based
                  on the following criteria:
                </p>
                <ol>
                  <li>
                    Priority given to siblings of children currently enrolled in
                    [Daycare Name].
                  </li>
                  <li>
                    Priority given to applicants residing within [Geographic
                    Area].
                  </li>
                  <li>
                    Priority given to applicants based on the date of
                    application submission.
                  </li>
                </ol>

                <h2 className="font-medium">4. Notification Process</h2>
                <p>
                  When a spot becomes available, parents/guardians will be
                  notified via email and/or phone call using the contact
                  information provided in their application. Parents/guardians
                  must respond within [Response Deadline] to accept the offer of
                  enrollment.
                </p>

                <h2 className="font-medium">5. Acceptance Conditions</h2>
                <p>
                  To accept a spot from the waitlist, parents/guardians must:
                </p>
                <ul>
                  <li>
                    Confirm acceptance via email or phone call within the
                    specified response deadline.
                  </li>
                  <li>
                    Pay a non-refundable enrollment deposit of [Deposit Amount].
                  </li>
                  <li>
                    Complete and sign the enrollment agreement provided by
                    [Daycare Name].
                  </li>
                </ul>

                <h2 className="font-medium">6. Waitlist Duration</h2>
                <p>
                  Children will remain on the waitlist for a period of [Waitlist
                  Duration] from the date of application submission.
                  Parents/guardians may request to renew or update their
                  application status after this period.
                </p>

                <h2 className="font-medium">7. Cancellation Policy</h2>
                <p>
                  Parents/guardians may cancel their child's waitlist
                  application at any time by notifying [Daycare Name] in
                  writing. The application fee is non-refundable. Enrollment
                  deposits are refundable only if a cancellation request is
                  received within [Cancellation Deadline].
                </p>

                <h2 className="font-medium">8. Data Protection and Privacy</h2>
                <p>
                  [Daycare Name] is committed to safeguarding the privacy and
                  security of applicant information. Personal data collected
                  during the application process will be used solely for
                  enrollment management purposes and will not be shared with
                  third parties without consent.
                </p>

                <h2 className="font-medium">9. Terms Updates</h2>
                <p>
                  [Daycare Name] reserves the right to update these terms and
                  conditions at any time. Parents/guardians will be notified of
                  any changes via email or on the [Daycare Name] website.
                </p>

                <div class="contact-info">
                  <h2 className="font-medium">10. Contact Information</h2>
                  <p>
                    For inquiries or assistance regarding the waitlist process,
                    please contact Daycare Hub at support@daycarehub.ca.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </Fragment>
  );
};

export default Terms;
