import logo from "../../assets/logo.svg";
import logoWhite from "../../assets/logo-white.svg";
import logoColoured from "../../assets/logo-coloured.svg";
import yellowBlurb from "../../assets/yellow-blurb.svg";
import blueBlurb from "../../assets/blue-blurb.svg";
import pinkBlurb from "../../assets/pink-blurb.svg";
import pinkBlurbFull from "../../assets/pink-blurb-full.svg";
import purpleBlurb from "../../assets/purple-blurb.svg";
import greenBlurb from "../../assets/green-blurb.svg";
import loadingGif from "../../assets/loading.gif";
import avatar from "../../assets/avatar.svg";
import profileAvatar from "../../assets/profile-big.svg";
import profile from "../../assets/profile.svg";
import daycareSvg from "../../assets/daycareInfo.svg";
import childInforSVG from "../../assets/childInfo.svg";
import dashboardSvg from "../../assets/dashboard.svg";
import logoutSvg from "../../assets/logoutSvg.svg";
import purpleArrow from "../../assets/purpleArrow.svg";
import messageIcon from "../../assets/message-icon.svg";
import parentIcon from "../../assets/parent.png";
import carecenter from "../../assets/carecenter.png";
import supportSvg from "../../assets/support.svg";
import arrowSidebar from "../../assets/arrowSidebar.svg";
import successSvg from "../../assets/success.svg";
import regImage from "../../assets/reg-image.svg";
import daycareDashboard from "../../assets/daycareDashboard.svg";
import deleteSvg from "../../assets/deleteSvg.svg";
import geolocationIcon from "../../assets/geolocationIcon.png";
import downloadSvg from "../../assets/download.svg";
import iconSvg from "../../assets/icon_info.svg";
import printSvg from "../../assets/printSvg.svg";
import trashSvg from "../../assets/trashSvg.svg";
import exclamationMark from "../../assets/exclamationMark.png";
import styles from "./styles.css";

export default {
  logo,
  logoWhite,
  logoColoured,
  yellowBlurb,
  blueBlurb,
  pinkBlurb,
  pinkBlurbFull,
  purpleBlurb,
  purpleArrow,
  greenBlurb,
  loadingGif,
  avatar,
  profileAvatar,
  profile,
  daycareSvg,
  childInforSVG,
  dashboardSvg,
  logoutSvg,
  messageIcon,
  parentIcon,
  carecenter,
  supportSvg,
  arrowSidebar,
  successSvg,
  regImage,
  daycareDashboard,
  deleteSvg,
  geolocationIcon,
  downloadSvg,
  iconSvg,
  printSvg,
  trashSvg,
  exclamationMark,
};
