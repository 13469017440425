import { createContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    // Initialize auth state with data from local storage or an empty object
    const [auth, setAuth] = useState(() => {
        try {
            const storedAuth = JSON.parse(localStorage.getItem("auth"));
            return storedAuth || {};
        } catch (error) {
            console.error("Error parsing auth data from localStorage:", error);
            return {};
        }
    });

    const [persist, setPersist] = useState(false);

    useEffect(() => {
        // Persist auth state to local storage whenever it changes
        localStorage.setItem("auth", JSON.stringify(auth));
    }, [auth]);

    useEffect(() => {
        let initialPersistState = false;
        try {
            initialPersistState = JSON.parse(localStorage.getItem("persist")) || false;
        } catch (error) {
            console.error("Error parsing persist state from localStorage:", error);
        }
        setPersist(initialPersistState);
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
