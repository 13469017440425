const apiBaseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL_PROD
    : process.env.REACT_APP_API_BASE_URL_DEV;

// Login | Reset | Logout | Password
export const baseURL = `${apiBaseUrl}/api/`;
export const LOGIN_URL = `${apiBaseUrl}/users/login/`;
export const RESEND_EMAIL_URL = `${apiBaseUrl}/users/resend-email-verify/`;
export const SEND_PASSWORD_RESET_EMAIL_URL = `${apiBaseUrl}/users/send-password-reset-email`;
export const ANONYMOUS_CHANGE_PASSWORD = `${apiBaseUrl}/users/anonymous-change-password/`;
export const RESET_PASSWORD = `${apiBaseUrl}/users/reset-password/`;
export const EMAIL_VERIFY = `${apiBaseUrl}/users/email-verify/`;
export const USER_LOGOUT = `${apiBaseUrl}/users/logout/`;
export const USER_REFRESH = `${apiBaseUrl}/users/refresh/`;
export const AUTHENTICATED_CHANGE_PASSWORD_URL = `${apiBaseUrl}/users/authenticated-change-password/`;
export const GOOGLE_API_KEY_URL = `${apiBaseUrl}/users/get-google-api-key/`;
export const SUPPORT_EMAIL = "info@daycarehub.ca";

//Parent URLS
export const GET_CURRENT_PARENT_PROFILE_URL = `${apiBaseUrl}/parent/api/get-current-parent-profile/`;
export const CREATE_PARENT_PROFILE_URL = `${apiBaseUrl}/parent/api/create-parent-profile/`;
export const UPDATE_PARENT_PROFILE_URL = `${apiBaseUrl}/parent/api/update-parent-profile/`;
export const PARENT_SIGNUP_URL = `${apiBaseUrl}/users/parent-signup/`;
export const ADD_CHILD = `${apiBaseUrl}/parent/api/add-child/`;
export const UPDATE_CHILD = `${apiBaseUrl}/parent/api/update-child-detail/`;
export const GET_CHILDREN_LIST_URL = `${apiBaseUrl}/parent/api/get-my-children-list/`;
export const DELETE_CHILD = `${apiBaseUrl}/parent/api/delete-child/`;
export const GET_CENTER_PROFILE_BY_SLUG = `${apiBaseUrl}/parent/api/get-center-profile-by-slug/`;
export const GET_WAITLISTS_BY_CENTER_BY_SLUG = `${apiBaseUrl}/parent/api/get-waitlists-by-center-by-slug/`;
export const GET_MY_CHILDREN_LIST = `${apiBaseUrl}/parent/api/get-my-children-list/`;
export const REGISTER_CHILD_ON_WAITLIST = `${apiBaseUrl}/parent/api/register-a-child-on-waitlist/`;
export const GET_REGISTERATION_CHILD_ON_WAITLIST = `${apiBaseUrl}/parent/api/get-child-registeration-and-position-on-waitlist/`;
export const GET_CHILD_DETAIL_BY_PARENT = `${apiBaseUrl}/parent/api/get_child_detail_by_parent/`;
export const PARENT_DELETE_CHILD_FROM_WAITLIST = `${apiBaseUrl}/parent/api/parent-remove-child-from-waitlist/`;
export const CONFIRM_CHILD_ACCEPTANCE = `${apiBaseUrl}/parent/api/confirm-child-acceptance/`;
export const DECLINE_CHILD_ACCEPTANCE = `${apiBaseUrl}/parent/api/decline-child-acceptance/`;

//Center URLS
export const GET_CURRENT_CENTER_PROFILE_URL = `${apiBaseUrl}/center/api/get-current-center-profile/`;
export const CREATE_CENTER_PROFILE_URL = `${apiBaseUrl}/center/api/create-center-profile/`;
export const UPDATE_CENTER_PROFILE_URL = `${apiBaseUrl}/center/api/update-center-profile/`;
export const CENTER_SIGNUP_URL = `${apiBaseUrl}/users/center-signup/`;
export const GET_ALL_CENTERS = `${apiBaseUrl}/center/api/get-all-centers/`;
export const GET_WAITLISTS_BY_CENTER = `${apiBaseUrl}/center/api/get-waitlists-by-center/`;
export const GET_REGISTRATION_IN_WAITLIST = `${apiBaseUrl}/center/api/get-registrations-in-waitlist/`;
export const CREATE_WAITLIST = `${apiBaseUrl}/center/api/create-waitlist/`;
export const DELETE_CHILD_FROM_WAITLIST = `${apiBaseUrl}/center/api/center-delete-child-from-waitlist/`;
export const GIVE_REASON_REJECTION = `${apiBaseUrl}/center/api/center-give-reason-for-rejection/`;
export const ACCEPT_CHILD_REGISTRATION = `${apiBaseUrl}/center/api/accept-child-registration/`;
export const DELETE_WAITLIST = `${apiBaseUrl}/center/api/delete-waitlist/`;
export const UPDATE_WAITLIST = `${apiBaseUrl}/center/api/update-waitlist_detail/`;
export const REJECTED_REGISTRATIONS = `${apiBaseUrl}/center/api/get-rejected-registrations-for-each-waitlist/`;
export const ACCEPTED_REGISTRATIONS = `${apiBaseUrl}/center/api/get-accepted-registrations-for-each-waitlist/`;
export const GET_QUALIFIED_REGISTRATIONS = `${apiBaseUrl}/center/api/get-qualified-regs-by-expected-start-date/`;
