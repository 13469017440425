import { useState, useEffect, useMemo } from "react";
import axiosInstance from "../../../api/axios";
import assetImports from "../../AssetImport/assetImports";
import { GET_ALL_CENTERS, GOOGLE_API_KEY_URL } from "../../../apiUrls";
import ProfileBar from "../../NameProfileBar/nameprofilebar";
import useAuth from "../../../hooks/useAuth";
import getCurrentProfile from "../../Profile/profileAPI";
import useRefreshToken from "../../../hooks/useRefreshToken";
import Banner from "../../Banner/banner";
import Sidebar from "../../Sidebar/sidebar";
import Select from "react-select";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import DaycareDetails from "./waitlistDetails";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import FilterSection from "./FilterSection";
import styles from "./styles.css";
import Preloader from "../../../Preloader";

const DayCareSearch = () => {
  let { slug } = useParams();
  const [showDisplay, setShowDisplay] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [profile_first_name, setProfileFirstName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [centers, setCenters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterProvince, setFilterProvince] = useState("");
  const [filterCity, setFilterCity] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [activePage, setActivePage] = useState(1);

  const [maxDistance, setMaxDistance] = useState(200); // Initial value for max distance
  const [userLocation, setUserLocation] = useState({
    latitude: "",
    longitude: "",
  }); // State variable to store user location

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCenters, setTotalCenters] = useState(0);
  const centersPerPage = 4;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); // Function to close the sidebar
  };

  const toggleDistanceDisplay = () => {
    setShowDisplay((prev) => !prev);
  };

  const calculateDistance = (userCord, centerCord) => {
    const R = 6371; // Radius of the Earth in kilometers
    const rlat1 = deg2rad(userCord.latitude);
    const rlat2 = deg2rad(centerCord.latitude);
    const difflat = rlat2 - rlat1;
    const difflon = deg2rad(centerCord.longitude - userCord.longitude);
    const distance =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) *
              Math.cos(rlat2) *
              Math.sin(difflon / 2) *
              Math.sin(difflon / 2)
        )
      );
    return distance.toFixed(2) + " km";
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  useEffect(() => {
    const fetchProfileAndDaycares = async () => {
      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
      try {
        // Fetch API key
        const keyResponse = await axiosInstance.get(GOOGLE_API_KEY_URL, {
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        });
        const apiKey = keyResponse.data.google_api_key.key;

        // Fetch user profile
        const profileResponse = await getCurrentProfile(auth.accessToken);
        const userProfile = profileResponse.data;
        setProfileFirstName(userProfile.first_name);

        // Get user address coordinates
        const userCoordinates = await getAddressCoordinates(
          userProfile.street_address,
          userProfile.city,
          userProfile.province,
          apiKey
        );
        setUserLocation({
          city: userProfile.city,
          province: userProfile.province,
          coordinates: userCoordinates, // Add coordinates to userLocation object
        });

        // Fetch daycares and calculate distance
        const centersWithDistance = await fetchDaycaresAndCalculateDistance(
          userCoordinates,
          apiKey
        );

        // Update state with centers including distance
        setCenters(centersWithDistance);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
        if (error.response && error.response.status === 401) {
          setErrMsg("Not Authorized");
          Navigate("logout");
        } else {
          setErrMsg("Error fetching data. Please try again later.");
        }
        navigate("/login");
      }
    };

    fetchProfileAndDaycares();
  }, [auth.accessToken]);

  const fetchDaycaresAndCalculateDistance = async (userCoordinates, apiKey) => {
    try {
      // Fetch all centers
      const centersResponse = await axiosInstance.get(GET_ALL_CENTERS, {
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      });
      const allCenters = centersResponse.data;

      // Calculate distance for each center
      const centersWithDistance = await Promise.all(
        allCenters.map(async (center) => {
          const centerCoordinates = await getAddressCoordinates(
            center.street_address,
            center.city,
            center.province,
            apiKey
          );
          const distance = calculateDistance(
            userCoordinates,
            centerCoordinates
          );
          return { ...center, distance }; // Add distance to center object
        })
      );

      // Update provinces and cities
      const uniqueProvinces = [
        ...new Set(allCenters.map((center) => center.province)),
      ];
      const uniqueCities = [
        ...new Set(allCenters.map((center) => center.city)),
      ];
      setProvinces(uniqueProvinces);
      setCities(uniqueCities);

      return centersWithDistance;
    } catch (error) {
      // Handle errors
      console.error("Error fetching centers or calculating distance:", error);
      throw error;
    }
  };

  const getAddressCoordinates = async (address, city, province, apiKey) => {
    try {
      // Encode the address, city, and province for the API request
      const encodedAddress = encodeURIComponent(
        `${address}, ${city}, ${province}`
      );

      // Make a GET request to the Google Geocoding API
      const response = await axiosInstance.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`
      );
      // Parse the response and extract the latitude and longitude
      const { results } = response.data;
      if (results && results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        return { latitude: lat, longitude: lng };
      } else {
        throw new Error("No results found");
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      // Handle errors, such as when the API request fails or no results are found
      return { latitude: "", longitude: "" };
    }
  };

  const filteredCities = useMemo(() => {
    // Filter cities based on the selected province
    return cities.filter((city) => {
      const cityProvince = centers.find(
        (center) => center.city === city
      )?.province;
      return cityProvince === filterProvince;
    });
  }, [filterProvince, cities, centers]);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalCenters / centersPerPage); i++) {
    pageNumbers.push(i);
  }

  const centersData = useMemo(() => {
    let computedCenters = centers;

    computedCenters.sort((a, b) => {
      const distanceA = parseFloat(a.distance) || 0;
      const distanceB = parseFloat(b.distance) || 0;
      return distanceA - distanceB;
    });
    if (maxDistance !== undefined && showDisplay && maxDistance !== 0) {
      // Added filterProvince condition
      computedCenters = computedCenters.filter(
        (center) => parseFloat(center.distance) <= maxDistance
      );
    }

    if (searchTerm) {
      computedCenters = computedCenters.filter((center) =>
        center.center_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filter by city
    if (filterCity && filterCity !== "City") {
      computedCenters = computedCenters.filter((center) =>
        center.city.toLowerCase().includes(filterCity.toLowerCase())
      );
    }

    // Filter by province
    if (filterProvince && filterProvince !== "Province") {
      computedCenters = computedCenters.filter((center) =>
        center.province.toLowerCase().includes(filterProvince.toLowerCase())
      );
    }

    setTotalCenters(computedCenters.length);

    //Current Page slice
    return computedCenters.slice(
      (currentPage - 1) * centersPerPage,
      (currentPage - 1) * centersPerPage + centersPerPage
    );
  }, [
    centers,
    currentPage,
    searchTerm,
    filterCity,
    filterProvince,
    maxDistance,
    showDisplay,
  ]);
  // Change page

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setActivePage(pageNumber); // Update the active page number
  };

  const handleMaxDistanceChange = (event) => {
    setMaxDistance(event);
  };

  const resetFilter = () => {
    setSearchTerm("");
    setFilterProvince("");
    setFilterCity("");
    setCurrentPage(1);
    setMaxDistance(200);
    setShowDisplay(false);
  };

  if (slug) {
    return <DaycareDetails slug={slug} />;
  }

  if (!dataLoaded) {
    return <Preloader />;
  }

  return (
    <section>
      {loading && (
        <div className="overlay-loading white">
          <div className="flex flex-wrap items-center justify-center flex-col">
            <img src={assetImports.loadingGif} alt="Loading" />
            <p className="italic">
              Getting Daycares Close to your current location...
            </p>
          </div>
        </div>
      )}
      <img id="blueBlurbProfile" src={assetImports.blueBlurb} alt="" />
      <img id="greenBlurbProfile" src={assetImports.greenBlurb} alt="" />
      <img id="pinkBlurbFullProfile" src={assetImports.pinkBlurbFull} alt="" />
      <div>
        <Banner toggleSidebar={toggleSidebar} title={`DAYCARE SEARCH`} />
      </div>
      <div className="flex flex-wrap sidebar-container relative">
        <Sidebar isOpen={isSidebarOpen} onClose={handleCloseSidebar} />
        <div className="content-sidebar">
          <div className="inner no-mobile-inner">
            <div className="bigger-image">
              <ProfileBar
                name={`Hi ${profile_first_name}`}
                text="Let's help you with your Day Care search"
                img={assetImports.profileAvatar}
              />
            </div>
            <div className="box-container box-inner">
              <p className="choose-text">
                Choose your Province and City to find Daycares around you
              </p>
            </div>
            <div className="col-container box-inner relative">
              <img id="dcsearch" src={assetImports.pinkBlurbFull} alt="" />
              <FilterSection
                provinces={provinces}
                cities={filteredCities}
                filterProvince={filterProvince}
                filterCity={filterCity}
                maxDistance={maxDistance}
                searchTerm={searchTerm}
                handleFilterProvinceChange={setFilterProvince}
                handleFilterCityChange={setFilterCity}
                handleSearchTermChange={setSearchTerm}
                handleMaxDistanceChange={handleMaxDistanceChange}
                resetFilter={resetFilter}
                showDisplay={showDisplay}
                toggleDistanceDisplay={toggleDistanceDisplay}
              />

              <div className="border-secondary center-holder relative">
                <div className="text-block">
                  <h3 className="text-filter">DAY CARE SEARCH</h3>
                  {filterCity && !filterProvince && (
                    <h3 className="text-filter">{filterCity}</h3>
                  )}
                  {filterProvince && !filterCity && (
                    <h3 className="text-filter">{filterProvince}</h3>
                  )}
                  {filterCity && filterProvince && (
                    <h3 className="text-filter">
                      {filterCity}, {filterProvince}
                    </h3>
                  )}
                  <p className="italic">
                    Click on the plus button to add child to a waitlist
                  </p>
                  {/* {maxDistance && (
                                        <p className='italic nomargin'>Distance: {maxDistance}km</p>
                                    )} */}
                </div>
                {centersData.length === 0 ? (
                  <p className="pink">No daycare centers found.</p>
                ) : (
                  centersData.map((center) => {
                    return (
                      <div
                        key={center.id}
                        className="border-secondary daycare-card"
                      >
                        <div className="card-body-flex">
                          <Link
                            className="linkcenter border-secondary secondary"
                            to={`/daycares/${center.slug}`}
                          >
                            +
                          </Link>
                          <h3>{`${center.center_name}`}</h3>
                          <p className="smaller pink">
                            <a
                              className="black"
                              href={`mailto:${center.email}`}
                              aria-label={`Email ${center.center_name}`}
                              target="_blank"
                            >{`Email: ${center.email}`}</a>
                          </p>
                          <p className="smaller">
                            <a
                              className="black"
                              href={`tel:${center.phone}`}
                              aria-label={`Call ${center.center_name}`}
                              target="_blank"
                            >{`Phone: ${center.phone}`}</a>
                          </p>
                          <p className="smaller">{`Address: ${center.street_address}, ${center.city}, ${center.province}`}</p>
                          <p className="smaller pink font-semibold">{`Distance from profile address: ${center.distance}`}</p>
                        </div>
                      </div>
                    );
                  })
                )}

                <nav>
                  <ul className="pagination">
                    {pageNumbers.map((number) => (
                      <li
                        key={number}
                        className={`page-item ${
                          activePage === number ? "active" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(number)}
                          className="page-link"
                        >
                          {number}
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DayCareSearch;
