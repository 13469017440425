import React from 'react';
import assetImports from '../AssetImport/assetImports';
import styles from "./styles.css";

const Banner = ({ title, toggleSidebar }) => {
  return (
    <nav>
        <div className="banner">
            <div className='container-max flex flex-wrap justify-between items-center'>
                <div className='logo-image'>
                    <img src={assetImports.logoWhite} alt="Logo" />
                </div>
                <div className='title-block'>
                    <h1 className="text-white font-lguy">{title}</h1>
                </div>
                <div className='hamburger show-for-mobile' onClick={toggleSidebar}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="box-avatar hide-for-mobile">
                    <div className="bg-white avatar-div items-center justify-center flex flex-wrap">
                        <img src={assetImports.avatar} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
  );
};

export default Banner;
