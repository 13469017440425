import React, { useState, useEffect, useContext } from 'react';
import assetImports from '../AssetImport/assetImports';
import styles from "./styles.css";

const ProfileBar = ({ name, text, img, imgClass }) => {
  return (
    <div>
        <div className="profile-bar">
            <div className='container-inner'>
                <h1 className="text-white">{name}!</h1>
                <p className='text-white nomargin'>{text}</p>
                <img className="img-main" src={img} alt="" />
            </div>
        </div>
    </div>
  );
};

export default ProfileBar;
