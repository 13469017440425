import axiosInstance from "../../api/axios";
import React, { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate, Link } from "react-router-dom";
import assetImports from "../AssetImport/assetImports";
import styles from "./styles.css";
import { LOGIN_URL } from "../../apiUrls";
import getCenterProfile from "../Profile/centerProfileAPI";
import { jwtDecode } from "jwt-decode";

function CenterLogin() {
  const { setAuth, auth } = useAuth();

  const userRef = useRef();
  const errRef = useRef();
  const history = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsgCenter, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCareCenter, setIsCareCenter] = useState(false);
  const [isParent, setIsParent] = useState(false);

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (auth.accessToken) {
      const decodedToken = jwtDecode(auth.accessToken);
      // console.log('Decoded Token:', decodedToken);
      setIsCareCenter(decodedToken.is_care_center === true);
      setIsParent(decodedToken.is_parent === false);
      // console.log('isCareCenter:', isCareCenter);
      // console.log('isParent:', isParent);
    }
  }, [auth.accessToken]);

  useEffect(() => {
    if (auth.accessToken && isCareCenter) {
      const fetchProfileCenter = async () => {
        try {
          if (isCareCenter) {
            const response = await getCenterProfile(auth.accessToken);
            if (response.data) {
              // User profile exists, redirect to dashboard
              history("/center-dashboard");
            } else {
              history("/create-center-profile");
            }
          }
        } catch (error) {
          console.error("Error checking profile:", error);
          // Redirect to create profile in case of error
          if (isCareCenter) {
            history("/create-center-profile");
          }
        }
      };
      fetchProfileCenter();
    }
  }, [auth.accessToken, history, isCareCenter]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value.trim().toLowerCase());
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const fetchProfileCenter = async (accessToken) => {
    try {
      const response = await getCenterProfile(accessToken);
      if (response.data) {
        history("/center-dashboard");
      } else {
        history("/create-center-profile");
      }
    } catch (error) {
      history("/create-center-profile");
    }
  };

  const handleFormSubmitCenter = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (email.trim() === "" || password === "") {
      setErrMsg("Please fill out both email and password fields.");
      setLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.post(LOGIN_URL, { email, password });
      const accessToken = response?.data?.access;
      const refreshToken = response?.data?.refresh;
      const decodedToken = jwtDecode(accessToken);

      if (decodedToken.is_care_center === true) {
        setAuth({ email, accessToken, refreshToken });
        await fetchProfileCenter(accessToken);
        setPassword("");
      } else if (decodedToken.is_parent === true) {
        setErrMsg(
          "The Email provided is associated with a parent account, Please click on the portal link below to access to the parent portal"
        );
      } else {
        setErrMsg("User does not exist."); // or some other error message
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (error.response.status === 401) {
          if (error.response.data.error) {
            setErrMsg(error.response.data.error);
          } else {
            setErrMsg("Invalid Credentials");
          }
        } else if (error.response.status === 403) {
          setErrMsg("Account Not Verified");
        } else {
          setErrMsg("Error Login! Please try again later");
        }
      } else {
        setErrMsg("Network Error");
      }
      errRef.current.focus();
    } finally {
      setLoading(false);
    }
  };

  // const togglePersist = () => {
  //     setPersist(prev => !prev);
  // }

  // useEffect(() => {
  //     localStorage.setItem("persist", persist);
  // }, [persist])

  return (
    <>
      <section className="login-holder flex flex-wrap justify-center items-center relative">
        {loading && (
          <div className="overlay-loading">
            <img src={assetImports.loadingGif} alt="Loading" />
          </div>
        )}
        <img id="blueBlurb" src={assetImports.blueBlurb} alt="" />
        <img id="purpleBlurb" src={assetImports.purpleBlurb} alt="" />
        <img id="greenBlurb" src={assetImports.greenBlurb} alt="" />
        <img id="pinkBlurb" src={assetImports.pinkBlurb} alt="" />
        <img id="pinkBlurbFull" src={assetImports.pinkBlurbFull} alt="" />
        <div className="container relative login-container">
          <img className="logo-image" src={assetImports.logo} alt="Logo" />
          <div className="relative login-box">
            <img id="yellowBlurb" src={assetImports.yellowBlurb} alt="" />
            <div className="relative login-box">
              <div className="login-form center-form bg-primary border-primary relative">
                <p
                  id="loginerror"
                  ref={errRef}
                  className={errMsgCenter ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsgCenter}
                </p>
                <h1 className="font-lguy primary text-center">DAYCARE LOGIN</h1>
                <form id="center-login-form" onSubmit={handleFormSubmitCenter}>
                  <label htmlFor="email"></label>
                  <input
                    className="border-primary"
                    placeholder="Email Address"
                    type="email"
                    name="email"
                    id="email-center"
                    ref={userRef}
                    autoComplete="off"
                    onChange={handleChange}
                    required
                  />

                  <label htmlFor="password"></label>
                  <input
                    className="border-primary"
                    placeholder="Password"
                    type="password"
                    name="password"
                    id="password-center"
                    onChange={handleChange}
                    required
                  />

                  <button className="full primary button-custom" type="submit">
                    LOG IN
                  </button>
                  {/* <div className="persistCheck">
                                        <input
                                            type="checkbox"
                                            id="persist-center"
                                            onChange={togglePersist}
                                            checked={persist}
                                        />
                                        <label className="text-white" htmlFor="persist-center">Trust This Device</label>
                                    </div> */}
                </form>
                <div className="flex flex-wrap justify-between items-center">
                  <Link className="primary fpassword" to="/forgot-password">
                    Forgot Password?
                  </Link>
                  <Link className="pink fpassword" to="/login">
                    Parent Portal
                  </Link>
                  <span className="text-acc">Don't have an account?</span>
                  <Link
                    className="button-custom pink small"
                    to="/center-signup"
                  >
                    {" "}
                    SIGN UP{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CenterLogin;
