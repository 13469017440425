import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axios';
import { GOOGLE_API_KEY_URL } from '../apiUrls';
import useAuth from '../hooks/useAuth';

const ApiKeyFetcher = ({ children }) => {
    const [apiKey, setApiKey] = useState('');
    const { auth } = useAuth();


    useEffect(() => {
        const fetchApiKey = async () => {
            try {
                // console.log('Fetching API key...');
                const response = await axiosInstance.get(GOOGLE_API_KEY_URL , {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`
                    }
                });
                const apiKey = response.data.google_api_key.key;
                setApiKey(apiKey);
            } catch (error) {
                console.error('Error fetching API key:', error);
            }
        };
    
        fetchApiKey();
    }, [auth.accessToken]);
    
    return (
        <>
            {apiKey && React.cloneElement(children, { apiKey })}
        </>
    );
};

export default ApiKeyFetcher;
